<template>
  <div class="password">
    <el-radio-group v-model="password.use" @change="changeEvent">
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="password" ref="dictData" label-width="150px" @submit.native.prevent>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.PasswordType')+'：'"
      >
        <el-row>
          <el-col :span="24">
            <el-select
              size="small"
              v-model="password.passCodeType"
              :disabled="password.use==0"
              style="width:240px"
              @change="changeSelect"
            >
              <el-option
                v-for="item in passCodeType"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.passCodeType.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span v-if="password.passCodeType == 1" style="margin-left: 5px; color: #807e7e" class="hint"
              >{{$t('DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.SpanText')}}</span
            >
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 复杂密码 -->
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.PasswordComplexity')+'：'"
        prop="name"
        v-if=" password.passCodeType == 0"
      >
        <el-row>
          <el-col :span="4">
            <el-select
              size="small"
              v-model="password.passwordComplexity"
              :disabled="password.use==0"
              style="width:240px;"
              @change="changeSelect"
            >
              <el-option
                v-for="item in passwordComplexity"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.passwordComplexity.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.PasswordLength')+'：'"
        :required="true"
        v-if="password.passCodeType == 0" >
        <el-row>
          <el-col :span="4">
            <el-input
              style="width:240px;"
              size="small"
              v-model="password.minLength"
              :disabled="true"
            ></el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 简单密码 -->
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.PINCodeLength')+'：'"
        v-if="password.passCodeType == 1"
      >
        <el-row>
          <el-col :span="4">
            <el-input
              style="width:240px;"
              size="small"
              v-model="password.pinNumber"
              :disabled="true"
            ></el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.DigitalPassword')+'：'"
        :required="true"
        v-if="password.passCodeType == 1"
      >
        <el-row>
          <el-col :span="12">
            <el-input
              style="width:240px;"
              size="small"
              v-model.trim="password.password"
              :placeholder="$t('DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.placeholder')"
              @input="changeText"
              :maxlength="4"
            ></el-input>
            <span v-if="password.passCodeType == 1" style="margin-left: 5px; color: #807e7e" class="hint"
              >{{$t('DeviceManage.DevicePolicy.AndroidPolicy.PasswordSettings.placeholder')}}</span
            >
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      password: {
        use: "0",
        passCodeType: "0",
        passwordComplexity: "0",
        minLength: "8",
        pinNumber: "4",
        password: "",
      },
      passCodeType: [
        {
          value: "0",
          label: "复杂密码",
        },
        {
          value: "1",
          label: "简单密码",
        },
      ],
      passwordComplexity: [
        {
          value: "0",
          label: "字母+数字",
        },
        {
          value: "1",
          label: "字母+特殊符号",
        },
        {
          value: "2",
          label: "字母+数字+特殊符号",
        },
      ],
    };
  },
  created() {
    if(this.dataList.configContent.password.use == "1"){
      this.password = this.dataList.configContent.password
    }
    if(this.dataList&&!this.dataList.configContent.password.minLength){
      this.password.minLength = "8"
    }
    if(this.dataList&&!this.dataList.configContent.password.pinNumber){
      this.password.pinNumber = "4"
    }
  },
  mounted() {
    
  },
  methods: {
    TheCallback() {
      this.$emit("submitSave", {
        password:this.password
      });
    },
    changeEvent(){
      this.TheCallback()
    },
    changeSelect(){
      this.TheCallback()
    },
    changeText(){
      this.TheCallback()
    }
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
</style>