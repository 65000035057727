<template>
  <div class="limit">
    <el-radio-group v-model="limit.use" @change="changeEvent">
      <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
      <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
    </el-radio-group>
    <div class="limitBox">
      <!-- 数据保护 -->
      <div class="protection">
        <div class="protectionWord" style="color: #807e7e">
          {{ $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.DataProtection") + "：" }}
        </div>
        <div class="checkbox" style="margin-top: 10px; width: 176px">
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowBluetooth')"
            v-model="limit.allowBluetooth"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <template>
            <el-checkbox
              :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowCamera')"
              v-model="limit.allowCamera"
              true-label="1"
              false-label="0"
              @change="allowCameralimits"
              :disabled="limit.use == 0"
            ></el-checkbox>
            <!-- v6.1.2新增 -->
            <div style="display: flex; width: 500px; margin-left: 25px" v-if="limituse">
              <div
                style="
                  width: 100px;
                  height: 28px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                "
                size="small"
                prop="name"
              >
                <!--    element plus 的popover组件如何去自适应位置
              :fallback-placements="['bottom', 'top', 'right', 'left']"当 Popover 弹出框的位置接近边界时，应该自动切换到相反的方向进行显示
              popper-class添加最大高度，超过出现滚动条
              -->
                <el-popover
                  placement="bottom"
                  width="300"
                  popper-class="max-h-300px overflow-auto"
                  :fallback-placements="['bottom', 'top', 'right', 'left']"
                  trigger="hover"
                  append-to-body
                >
                  <el-button
                    style="margin: 3px"
                    type="primary"
                    size="mini"
                    v-for="(item, index) in limit.allowCameraList"
                    :key="index"
                    >{{ item.name }}（{{ item.identifier }}）<i
                      class="el-icon-delete el-icon--right"
                      @click="deleteGeograp(item)"
                    ></i
                  ></el-button>
                  <el-button size="mini" @click="allDell">{{
                    $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.allDelete")
                  }}</el-button>
                  <template slot="reference">
                    <div>
                      <span
                        style="
                          font-size: 12px;
                          background: #f1f1f1;
                          padding: 3px;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;
                          cursor: pointer;
                        "
                        v-for="(item, index) in limit.allowCameraList"
                        :key="index"
                        :show-overflow-tooltip="true"
                      >
                        {{ item.name }}（{{ item.identifier }}）</span
                      >
                    </div>
                  </template>
                </el-popover>
              </div>
              <el-button
                @click="addApply"
                type="primary"
                size="small"
                style="margin-left: 5px; padding: 6px 3px"
                icon="el-icon-plus"
                v-if="limituse"
                :disabled="limit.use == 0"
                >{{
                  $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.application")
                }}</el-button
              >
            </div>
            <!-- 多选内容 -->
            <!-- </el-from> -->
          </template>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowScreenshots')"
            v-model="limit.allowScreenshots"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowRecord')"
            v-model="limit.allowRecord"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSharefile')"
            v-model="limit.allowSharefile"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowADBPort')"
            v-model="limit.allowADBPort"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowUSB')"
            v-model="limit.allowUSB"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowPreBrowser')"
            v-model="limit.allowPreBrowser"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSDWrite')"
            v-model="limit.allowSDWrite"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <!-- v6.1.2 新增 -->
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowBackup')"
            v-model="limit.allowBackup"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowOtgStorage')"
            v-model="limit.allowOtgStorage"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowMtp')"
            v-model="limit.allowMtp"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
        </div>
      </div>
      <!-- 系统设置 -->
      <div class="setting">
        <div class="settingWord" style="color: #807e7e">
          {{ $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.SystemSettings") + "：" }}
        </div>
        <div class="checkbox" style="margin-top: 10px">
          <template>
            <el-checkbox
              :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowLocation')"
              v-model="allowLocationStatus"
              true-label="1"
              false-label="0"
              @change="allowLocationLimits"
              :disabled="limit.use == 0"
            >
            </el-checkbox>
            <el-radio-group
              @change="elRadioChange"
              v-model="limit.allowLocation"
              style="margin-left: 24px"
              v-if="limitLocation"
            >
              <el-radio :label="'1'" style="line-height: 2" :disabled="limit.use == 0">{{
                $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.allowed")
              }}</el-radio>
              <el-radio :label="'2'" style="line-height: 2" :disabled="limit.use == 0">{{
                $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.forceOn")
              }}</el-radio>
            </el-radio-group>
          </template>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowNFC')"
            v-model="limit.allowNFC"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowInfraRed')"
            v-model="limit.allowInfraRed"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowAddAccount')"
            v-model="limit.allowAddAccount"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="
              $t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowRestoreSettings')
            "
            v-model="limit.allowRestoreSettings"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowDeveloper')"
            v-model="limit.allowDeveloper"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowUpdateDate')"
            v-model="limit.allowUpdateDate"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowAirplaneMode')"
            v-model="limit.allowAirplaneMode"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowDropDownMenu')"
            v-model="limit.allowDropDownMenu"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSafeMode')"
            v-model="limit.allowSafeMode"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowTurnOff')"
            v-model="limit.allowTurnOff"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSysUpdate')"
            v-model="limit.allowSysUpdate"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <!-- v6.1.2 新增 -->
          <!-- <el-checkbox label="禁止自动旋转 *" v-model="limit.allowRotate" true-label="1" false-label="0" @change="limits"
            :disabled="limit.use == 0"></el-checkbox> -->
        </div>
      </div>
      <!-- 网络设置 -->
      <div class="setting">
        <div class="settingWord" style="color: #807e7e">
          {{ $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.NetworkSettings") + "：" }}
        </div>
        <div class="checkbox" style="margin-top: 10px">
          <!-- v6.1.2新增 -->
          <template>
            <el-checkbox
              :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowWifi')"
              v-model="allowWifis"
              true-label="1"
              false-label="0"
              @change="allowWifiLimits"
              :disabled="limit.use == 0"
            ></el-checkbox>
            <el-radio-group
              @change="elWifiChange"
              v-model="limit.allowWifi"
              style="margin-left: 24px"
              v-if="limitWifi"
            >
              <el-radio :label="'1'" style="line-height: 2" :disabled="limit.use == 0">{{
                $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.allowed")
              }}</el-radio>
              <el-radio :label="'2'" style="line-height: 2" :disabled="limit.use == 0">{{
                $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.forceOn")
              }}</el-radio>
            </el-radio-group>
          </template>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowWifiSet')"
            v-model="limit.allowWifiSet"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowHotspot')"
            v-model="limit.allowHotspot"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowWIFIDirect')"
            v-model="limit.allowWIFIDirect"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <!-- v6.1.2新增 -->
          <template>
            <el-checkbox
              :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowDatalinks')"
              v-model="allowDatalinks"
              true-label="1"
              false-label="0"
              @change="allowDataLimits"
              :disabled="limit.use == 0"
            ></el-checkbox>
            <el-radio-group
              @change="elDataChange"
              v-model="limit.allowDatalink"
              style="margin-left: 24px"
              v-if="limitDatalink"
            >
              <el-radio :label="'1'" style="line-height: 2" :disabled="limit.use == 0">{{
                $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.allowed")
              }}</el-radio>
              <el-radio :label="'2'" style="line-height: 2" :disabled="limit.use == 0">{{
                $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.forceOn")
              }}</el-radio>
            </el-radio-group>
          </template>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowAPN')"
            v-model="limit.allowAPN"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowVPN')"
            v-model="limit.allowVPN"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <!-- v6.1.2 新增 -->
          <el-checkbox
            :label="
              $t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowBluetoothShareNet')
            "
            v-model="limit.allowBluetoothShareNet"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowUsbShareNet')"
            v-model="limit.allowUsbShareNet"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
        </div>
      </div>
      <!-- 通讯设置 -->
      <div class="setting">
        <div class="settingWord" style="color: #807e7e">
          {{ $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.Communication") + "：" }}
        </div>
        <div class="checkbox" style="margin-top: 10px">
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowPhone')"
            v-model="limit.allowPhone"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowMakeCall')"
            v-model="limit.allowMakeCall"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowInboundCall')"
            v-model="limit.allowInboundCall"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowNote')"
            v-model="limit.allowNote"
            true-label="1"
            false-label="0"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSendSMS')"
            v-model="limit.allowSendSMS"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowAccSMS')"
            v-model="limit.allowAccSMS"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSecondSIM')"
            v-model="limit.allowSecondSIM"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="limit.use == 0"
          ></el-checkbox>
        </div>
      </div>
      <!-- 上海银行2024新增 -->
      <!-- 应用设置 -->
      <div class="setting">
        <div class="settingWord" style="color: #807e7e">应用设置：</div>
        <div class="checkbox" style="margin-top: 10px">
          <template>
            <el-checkbox
              label="禁止卸载应用 *"
              v-model="limit.allowUninstallApp"
              true-label="1"
              false-label="0"
              @change="allowUninstallApplimits"
              :disabled="limit.use == 0"
            ></el-checkbox>
            <div
              style="display: flex; width: 500px; margin-left: 25px"
              v-if="UninstallUse"
            >
              <div
                style="
                  width: 100px;
                  height: 28px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                "
                size="small"
                prop="name"
              >
                <!--    element plus 的popover组件如何去自适应位置
              :fallback-placements="['bottom', 'top', 'right', 'left']"当 Popover 弹出框的位置接近边界时，应该自动切换到相反的方向进行显示
              popper-class添加最大高度，超过出现滚动条
              -->
                <el-popover
                  placement="bottom"
                  width="300"
                  popper-class="max-h-300px overflow-auto"
                  :fallback-placements="['bottom', 'top', 'right', 'left']"
                  trigger="hover"
                  append-to-body
                >
                  <el-button
                    style="margin: 3px"
                    type="primary"
                    size="mini"
                    v-for="(item, index) in limit.allowUninstallAppList"
                    :key="index"
                    >{{ item.name }}（{{ item.identifier }}）<i
                      class="el-icon-delete el-icon--right"
                      @click="delapp(item)"
                    ></i
                  ></el-button>
                  <el-button size="mini" @click="delAllApps">{{
                    $t("DeviceManage.DevicePolicy.AndroidPolicy.limit.allDelete")
                  }}</el-button>
                  <template slot="reference">
                    <div>
                      <span
                        style="
                          font-size: 12px;
                          background: #f1f1f1;
                          padding: 3px;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;
                          cursor: pointer;
                        "
                        v-for="(item, index) in limit.allowUninstallAppList"
                        :key="index"
                        :show-overflow-tooltip="true"
                        >{{ item.name }}（{{ item.identifier }}）</span
                      >
                    </div>
                  </template>
                </el-popover>
              </div>
              <el-button
                @click="selectApps"
                type="primary"
                size="small"
                style="margin-left: 5px; padding: 6px 3px"
                icon="el-icon-plus"
                v-if="UninstallUse"
                :disabled="limit.use == 0"
                >选择应用</el-button
              >
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- v6.1.2 新增 -->
    <Eldialog
      v-if="geograpFlag"
      @handleClose="handleClose"
      :title="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.selectApp')"
      :dialogVisible="geograpFlag"
      :btnTitle="$t('public.Join')"
      :width="'900px'"
      :num="'470px'"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="deterGeograp"
    >
      <el-input
        size="small"
        v-model="inputText"
        style="width: 240px"
        @keyup.enter.native="searcInstall"
        :placeholder="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.networkAppBW.placeholder')
        "
      ></el-input>
      <el-button
        style="margin-left: 5px"
        type="primary"
        size="small"
        @click="searcInstall"
        >{{ $t("public.Inquire") }}</el-button
      >
      <el-table
        ref="multipleTable"
        :data="dataForm"
        stripe
        :row-key="getRowKeys"
        size="mini"
        tooltip-effect="dark"
        class="sort_table"
        :header-cell-style="{ background: '#f7f8fa' }"
        style="width: 100%; margin-top: 10px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('public.ApplicationName')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="identifier"
          :label="$t('public.ApplicationID')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="versionName"
          :label="$t('public.Version')"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :label="$t('public.ModificationTime')"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <pagination
        :page="dataQuery.currentPage"
        :limit="dataQuery.pageSize"
        :total="dataQuery.totle"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetLists"
      />
    </Eldialog>
    <!-- 上海银行2024新增 -->
    <Eldialog
      v-if="appListFlag"
      @handleClose="handleClose"
      :title="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.selectApp')"
      :dialogVisible="appListFlag"
      :btnTitle="$t('public.Join')"
      :width="'900px'"
      :num="'470px'"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="deterGeograp1"
    >
      <el-input
        size="small"
        v-model="inputText1"
        style="width: 240px"
        @keyup.enter.native="searchApps"
        :placeholder="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.networkAppBW.placeholder')
        "
      ></el-input>
      <el-button
        style="margin-left: 5px"
        type="primary"
        size="small"
        @click="searchApps"
        >{{ $t("public.Inquire") }}</el-button
      >
      <el-table
        ref="multipleTable"
        :data="dataInfo.dataForm"
        stripe
        :row-key="getRowKeys"
        size="mini"
        tooltip-effect="dark"
        class="sort_table"
        :header-cell-style="{ background: '#f7f8fa' }"
        style="width: 100%; margin-top: 10px"
        @selection-change="handleSelectionChange1"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('public.ApplicationName')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="identifier"
          :label="$t('public.ApplicationID')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="versionName"
          :label="$t('public.Version')"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :label="$t('public.ModificationTime')"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <pagination
        :page="dataInfo.currentPage"
        :limit="dataInfo.pageSize"
        :total="dataInfo.total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetLists1"
      />
    </Eldialog>
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog";
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    Eldialog,
    pagination,
  },
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      limit: {
        use: "0",
        allowBluetooth: "0",
        allowCamera: "0",
        allowScreenshots: "0",
        allowRecord: "0",
        allowSharefile: "0",
        allowCopyPaste: "0",
        allowADBPort: "0",
        allowUSB: "0",
        allowPreBrowser: "0",
        allowSDWrite: "0",
        allowLocation: "0",
        allowNFC: "0",
        allowInfraRed: "0",
        allowAddAccount: "0",
        allowRestoreSettings: "0",
        allowDeveloper: "0",
        allowUpdateDate: "0",
        allowAirplaneMode: "0",
        allowDropDownMenu: "0",
        allowSafeMode: "0",
        allowTurnOff: "0",
        allowWifi: "0",
        allowHotspot: "0",
        allowWIFIDirect: "0",
        allowDatalink: "0",
        allowAPN: "0",
        allowVPN: "0",
        allowPhone: "0",
        allowMakeCall: "0",
        allowInboundCall: "0",
        allowNote: "0",
        allowSendSMS: "0",
        allowAccSMS: "0",
        allowSecondSIM: "0",
        allowWifiSet: "0",
        allowSysUpdate: "0",
        // v6.1.2新增
        allowBackup: "0", //禁用设备备份
        allowOtgStorage: "0", //otg存储
        allowMtp: "0", //mtp数据传输
        allowRotate: "0", //自动旋转
        allowBluetoothShareNet: "0", //蓝牙共享网络
        allowUsbShareNet: "0", //usb共享网络
        allowCameraList: [],
        // 上海银行2024新增
        allowPrint: "0",
        allowChangeUser: "0",
        allowBackupAndRecovery: "0",
        allowFingerprintUnlock: "0",
        allowMuteMode: "1",
        allowMMS: "0",
        allowUninstallApp: "0",
        allowUninstallAppList: [],
      },
      allowLocationStatus: "0",
      // v6.1.2
      limitLocation: false, // 定位服务
      limitWifi: false, // wifi网络
      limitDatalink: false, // 移动网络
      limituse: false,
      allowWifis: "0", // wifi网络
      allowDatalinks: "0", // 移动网络
      // 上海银行2024新增
      UninstallUse: false,
      uninstallAppInfo: [], //内容展示
      dataInfo: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        dataForm: [],
      },
      appInfo: [], //内容展示
      networkAppBW: {
        use: "0",
        bwType: "1",
        list: [],
      },
      dataQuery: {
        // 分页
        currentPage: 1,
        pageSize: 10,
        totle: 0,
      },
      geograpFlag: false, // 弹框
      appListFlag: false, // 弹框
      inputText: "", // 弹框搜索
      inputText1: "", // 弹框搜索
      dataForm: [],
    };
  },
  created() {
    if (this.dataList.id && this.dataList.configContent.limit.use == 1) {
      this.limit = this.dataList.configContent.limit;
      if (!this.limit.allowCameraList) {
        this.$set(this.limit, "allowCameraList", []);
      }
      if (!this.limit.allowUninstallAppList) {
        this.$set(this.limit, "allowUninstallAppList", []);
      }
      if (this.limit.allowLocation == "0") {
        this.allowLocationStatus = "0";
        this.limitLocation = false;
      } else {
        this.limitLocation = true;
        this.allowLocationStatus = "1";
      }
      // WIFI网络
      if (this.limit.allowWifi == "0") {
        this.allowWifis = "0";
        this.limitWifi = false;
      } else {
        this.limitWifi = true;
        this.allowWifis = "1";
      }
      // 移动网络
      if (this.limit.allowDatalink == "0") {
        this.allowDatalinks = "0";
        this.limitDatalink = false;
      } else {
        this.limitDatalink = true;
        this.allowDatalinks = "1";
      }
      // 禁用摄像头
      if (this.limit.allowCamera == "0") {
        this.limituse = false;
      } else {
        this.limituse = true;
      }
      // 禁止卸载应用
      if (this.limit.allowUninstallApp == "0") {
        this.UninstallUse = false;
      } else {
        this.UninstallUse = true;
      }
    }
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    limits(item) {
      this.$emit("submitSave", {
        limit: this.limit,
      });
    },
    // v6.1.2 禁用摄像头
    allowCameralimits(item) {
      console.log(item, "99999");
      if (item === "1") {
        this.limituse = true;
      } else if (item === "0") {
        this.limituse = false;
      }
      this.$emit("submitSave", {
        limit: this.limit,
      });
    },
    allowLocationLimits(item) {
      if (item === "1") {
        this.limit.allowLocation = "1";
        this.limitLocation = true;
      } else if (item === "0") {
        this.limitLocation = false;
        this.limit.allowLocation = "0";
        this.allowLocationStatus = "0";
      }
      this.$emit("submitSave", {
        limit: this.limit,
      });
    },
    // 禁用WIFI网络
    allowWifiLimits(item) {
      if (item === "1") {
        this.limit.allowWifi = "1";
        this.limitWifi = true;
      } else if (item === "0") {
        this.limitWifi = false;
        this.limit.allowWifi = "0";
        this.allowWifis = "0";
      }
      this.$emit("submitSave", {
        limit: this.limit,
      });
    },
    // 禁用移动网络
    allowDataLimits(item) {
      if (item === "1") {
        this.limit.allowDatalink = "1";
        this.limitDatalink = true;
      } else if (item === "0") {
        this.limitDatalink = false;
        this.limit.allowDatalink = "0";
        this.allowDatalinks = "0";
      }
      this.$emit("submitSave", {
        limit: this.limit,
      });
    },
    changeEvent() {
      this.$emit("submitSave", {
        limit: this.limit,
      });
    },
    elRadioChange(item) {
      if (item === "2") {
        this.allowLocationStatus = "1";
        this.limit.allowLocation = "2";
      } else if (item === "1") {
        this.limit.allowLocation = "1";
        this.allowLocationStatus = "1";
      }
    },
    // 禁用WIFI网络
    elWifiChange(item) {
      if (item === "2") {
        this.allowWifis = "1";
        this.limit.allowWifi = "2";
      } else if (item === "1") {
        this.limit.allowWifi = "1";
        this.allowWifis = "1";
      }
    },
    // 禁用移动网络
    elDataChange(item) {
      if (item === "2") {
        this.allowDatalinks = "1";
        this.limit.allowDatalink = "2";
      } else if (item === "1") {
        this.limit.allowDatalink = "1";
        this.allowDatalinks = "1";
      }
    },
    // 禁止卸载应用
    allowUninstallApplimits(item) {
      console.log(item, "禁止卸载应用");
      if (item === "1") {
        this.UninstallUse = true;
      } else if (item === "0") {
        this.UninstallUse = false;
      }
      this.$emit("submitSave", {
        limit: this.limit,
      });
    },
    // 选择应用
    selectApps() {
      this.appListFlag = true;
      this.dataInfo.currentPage = 1;
      this.appKu();
    },
    async appKu() {
      var param = {
        searchName: this.inputText1,
        osType: "20",
        currentPage: this.dataInfo.currentPage,
        pageSize: this.dataInfo.pageSize,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/android/appLib/getDataInfo",
        param,
        true
      );
      this.dataInfo.total = res.data.total;
      this.dataInfo.dataForm = res.data.content;
      this.$nextTick(() => {
        this.dataInfo.dataForm.forEach((item, index) => {
          this.limit.allowUninstallAppList.forEach((i, j) => {
            // console.log(i,'----i----');
            if (item.id == i.id) {
              // this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
        console.log(this.limit.allowUninstallAppList, "应用数组");
      });
    },
    // 加入--禁止卸载应用
    deterGeograp1() {
      // this.limit.allowCameraList=[]
      this.multipleSelection.forEach((item, index) => {
        this.limit.allowUninstallAppList.push({
          id: item.id,
          name: item.name,
          identifier: item.identifier,
        });
      });
      var result = [];
      var obj = {};
      for (var i = 0; i < this.limit.allowUninstallAppList.length; i++) {
        // if (!obj[this.limit.allowUninstallAppList[i].name]) {
        //   result.push(this.limit.allowUninstallAppList[i]);
        //   obj[this.limit.allowUninstallAppList[i].name] = true;
        // }
        // if (
        //   !obj[this.limit.allowUninstallAppList[i].name] &&
        //   !obj[this.limit.allowUninstallAppList[i].identifier]
        // ) {
        //   result.push(this.limit.allowUninstallAppList[i]);
        //   obj[this.limit.allowUninstallAppList[i].name] = true;
        //   obj[this.limit.allowUninstallAppList[i].identifier] = true;
        // } else if (!obj[this.limit.allowUninstallAppList[i].identifier]) {
        //   result.push(this.limit.allowUninstallAppList[i]);
        //   obj[this.limit.allowUninstallAppList[i].identifier] = true;
        // } else if (!obj[this.limit.allowUninstallAppList[i].name]) {
        //   result.push(this.limit.allowUninstallAppList[i]);
        //   obj[this.limit.allowUninstallAppList[i].name] = true;
        // }
        var item = this.limit.allowUninstallAppList[i];  
        var combinedKey = item.name + '-' + item.identifier; // 组合name和identifier作为键
        // 如果name和identifier的组合是唯一的，就加入result  
        if (!obj[combinedKey]) {  
          result.push(item);  
          obj[combinedKey] = true; // 标记这个组合已经处理过  
        }
      }
      this.limit.allowUninstallAppList = result;
      let appResu = result;
      appResu.forEach((item, index) => {
        console.log(item.id, "========");
        this.uninstallAppInfo.push({
          id: item.id,
          name: item.name,
          identifier: item.identifier,
        });
        console.log(this.uninstallAppInfo, "this.appInfothis.uninstallAppInfo");
      });
      this.$emit("submitSave", {
        limit: this.limit,
      });
      this.appListFlag = false;
      this.inputText1 = "";
    },
    //查询
    searchApps() {
      this.dataInfo.currentPage = 1;
      this.appKu();
    },
    handleSelectionChange1(val) {
      console.log(val);
      this.multipleSelection = [...val];
    },
    handleParentGetLists1(page, limit) {
      this.dataInfo.currentPage = page;
      this.dataInfo.pageSize = limit;
      // 调用查询方法
      this.appKu();
    },
    // v6.1.2新增 添加应用
    handleClose() {
      this.geograpFlag = false;
      this.appListFlag = false;
      this.inputText1 = "";
      this.inputText = "";
    },
    handleParentGetLists(page, limit) {
      this.dataQuery.currentPage = page;
      this.dataQuery.pageSize = limit;
      // 调用查询方法
      this.application();
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = [...val];
    },
    // 加入
    deterGeograp() {
      // this.limit.allowCameraList=[]
      this.flagStatus = true;
      this.multipleSelection.forEach((item, index) => {
        this.limit.allowCameraList.push({
          id: item.id,
          name: item.name,
          identifier: item.identifier,
        });
      });
      var result = [];
      var obj = {};
      for (var i = 0; i < this.limit.allowCameraList.length; i++) {
      //   if (
      //     !obj[this.limit.allowCameraList[i].name] &&
      //     !obj[this.limit.allowCameraList[i].identifier]
      //   ) {
      //     result.push(this.limit.allowCameraList[i]);
      //     obj[this.limit.allowCameraList[i].name] = true;
      //     obj[this.limit.allowCameraList[i].identifier] = true;
      //   } else if (!obj[this.limit.allowCameraList[i].identifier]) {
      //     result.push(this.limit.allowCameraList[i]);
      //     obj[this.limit.allowCameraList[i].identifier] = true;
      //   } else if (!obj[this.limit.allowCameraList[i].name]) {
      //     result.push(this.limit.allowCameraList[i]);
      //     obj[this.limit.allowCameraList[i].name] = true;
      //   }
        var item = this.limit.allowCameraList[i];  
        var combinedKey = item.name + '-' + item.identifier; // 组合name和identifier作为键
        // 如果name和identifier的组合是唯一的，就加入result  
        if (!obj[combinedKey]) {  
          result.push(item);  
          obj[combinedKey] = true; // 标记这个组合已经处理过  
        }
      }
      this.limit.allowCameraList = result;
      let appResu = result;
      appResu.forEach((item, index) => {
        console.log(item.id, "========");
        this.appInfo.push({
          id: item.id,
          name: item.name,
          identifier: item.identifier,
        });
        console.log(this.appInfo, "this.appInfothis.appInfo");
      });
      this.$emit("submitSave", {
        limit: this.limit,
      });
      this.geograpFlag = false;
      this.$nextTick(() => {
        this.inputText = "";
      });
    },
    addApply() {
      this.geograpFlag = true;
      this.dataQuery.currentPage = 1;
      this.application();
    },
    async application() {
      var param = {
        searchName: this.inputText,
        osType: "20",
        currentPage: this.dataQuery.currentPage,
        pageSize: this.dataQuery.pageSize,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/android/appLib/getDataInfo",
        param,
        true
      );
      this.dataQuery.totle = res.data.total;
      this.dataForm = res.data.content;
      this.$nextTick(() => {
        this.dataForm.forEach((item, index) => {
          this.limit.allowCameraList.forEach((i, j) => {
            console.log(i, "----i----");
            if (item.id == i.id) {
              // this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      });
    },
    //查询
    searcInstall() {
      this.dataQuery.currentPage = 1;
      this.application();
    },
    // 删除
    deleteGeograp(val) {
      this.$nextTick(() => {
        this.limit.allowCameraList.forEach((item, i) => {
          if (item.id === val.id) {
            // i 为选中的索引
            this.limit.allowCameraList.splice(i, 1);
          }
        });
        this.multipleSelection = this.limit.allowCameraList;
      });
    },
    // 全部删除
    allDell() {
      this.limit.allowCameraList = [];
      this.multipleSelection = [];
    },
    // 删除-禁止卸载应用
    delapp(val) {
      this.$nextTick(() => {
        this.limit.allowUninstallAppList.forEach((item, i) => {
          if (item.id === val.id) {
            // i 为选中的索引
            this.limit.allowUninstallAppList.splice(i, 1);
          }
        });
        this.multipleSelection = this.limit.allowUninstallAppList;
      });
    },
    // 全部删除-禁止卸载应用
    delAllApps() {
      this.limit.allowUninstallAppList = [];
      this.multipleSelection = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.limit {
  padding-top: 20px;
  width: 100%;
  height: 100%;
}

.limitBox {
  width: 100%;
  display: flex;
}

.el-radio-group {
  margin-left: 150px;
}

.protection {
  width: 410px;
  display: flex;

  .protectionWord {
    width: 150px;
    text-align: right;
    height: 24px;
    line-height: 24px;
    margin: 8px 0;
    font-size: 14px;
  }
}

.checkbox {
  width: 160px;

  .el-checkbox {
    height: 24px;
    line-height: 24px;
  }
}

.setting {
  width: 300px;
  display: flex;

  .settingWord {
    width: 70px;
    text-align: right;
    height: 24px;
    line-height: 24px;
    margin: 8px 0;
    font-size: 14px;
  }
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}

::v-deep .el-checkbox__input.is- + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is- + span.el-radio__label {
  color: #807e7e;
}
</style>
<style>
/**  popper-class="max-h-300px overflow-auto"这个class属性是借助windcss，如果没安装这个，只需要补充以下样式即可 **/
.max-h-300px {
  max-height: 300px;
}
.overflow-auto {
  overflow: auto;
}
</style>
