<template>
  <div class="password">
    <el-radio-group v-model="password.use" @change="changeEvent">
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="dataList" ref="dataList" label-width="180px" @submit.native.prevent>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SimplePassword')+'：'">
        <el-checkbox
          :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.PasswordComplexity')"
          v-model="password.allowSimple"
          true-label="1"
          false-label="0"
          :disabled="password.use==0"
          @change="changeCheck"
        ></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.PasswordLength')+'：'">
        <el-checkbox
          :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.RequireAlphaNumber')"
          v-model="password.requireAlphaNumber"
          true-label="1"
          false-label="0"
          :disabled="password.use==0"
          @change="changeCheck"
        ></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.DigitalPassword')+'：'">
        <el-checkbox
          v-model="password.forcePin"
          true-label="1"
          false-label="0"
          :disabled="password.use==0"
          @change="changeCheck"
        ></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.MinLengthPassword')+'：'">
        <el-row>
          <el-col :span="12">
            <el-select
              size="small"
              style="width: 205px"
              v-model="password.minLength"
              @change="changeSelect"
              :disabled="password.use==0"
            >
              <el-option
                v-for="item in minLength"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanTextMinLength')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.MinComplexChars')+'：'">
        <el-row>
          <el-col :span="12">
            <el-select
              style="width: 205px"
              size="small"
              :disabled="password.use==0"
              v-model="password.minComplexChars"
              @change="changeSelect"
            >
              <el-option
                v-for="item in minComplexChars"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
             <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.MaxPinAgeinDays')+'：'" :required="true">
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              style="width: 205px"
              :disabled="password.use==0"
              v-model="password.maxPinAgeinDays"
              @input="changeText"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanTextMax')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.PinHistory')+'：'" :required="true">
        <el-row>
          <el-col :span="12">
            <el-input
              style="width: 205px"
              size="small"
              :disabled="password.use==0"
              v-model.trim="password.pinHistory"
              @input="changeText"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanTextStory')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.MaxFailedTimes')+'：'">
        <el-row>
          <el-col :span="12">
            <el-select
              style="width: 205px"
              size="small"
              :disabled="password.use==0"
              v-model="password.maxFailedTimes"
              @change="changeSelect"
            >
              <el-option
                v-for="item in maxFailedTimes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span style="margin-left:5px;color:#807e7e;" class="hint"
              >{{$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanTextTimes')}}</span
            >
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      password: {
        use: "0",
        allowSimple: "0",
        requireAlphaNumber: "0",
        forcePin: "0",
        minLength: "6",
        minComplexChars: "0",
        maxPinAgeinDays: "",
        pinHistory: "",
        maxFailedTimes: "4",
      },
      minLength: [
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "9",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "11",
          label: "11",
        },
        {
          value: "12",
          label: "12",
        },
        {
          value: "13",
          label: "13",
        },
        {
          value: "14",
          label: "14",
        },
        {
          value: "15",
          label: "15",
        },
        {
          value: "16",
          label: "16",
        },
      ],
      minComplexChars: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
      ],
      maxFailedTimes: [
        {
          value: "",
          label: "请选择",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "9",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
      ],
    };
  },
  created() {
    if(this.dataList.id) {
     this.password = this.dataList.configContent.password
     if(this.dataList.configContent.password.minLength==undefined){
       this.password.minLength = "6"
     }
     if(this.dataList.configContent.password.minComplexChars==undefined){
       this.password.minComplexChars = "0"
     }
    }
  },
  mounted() {},
  methods: {
    changeEvent(){
      this.$emit("submitSave",{
        password:this.password
      })
    },
    changeCheck(){
      this.$emit("submitSave",{
        password:this.password
      })
    },
    changeSelect(){
      this.$emit("submitSave",{
        password:this.password
      })
    },
    changeText(){
      this.$emit("submitSave",{
        password:this.password
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
</style>
