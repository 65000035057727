<template>
  <div class="password">
    <el-radio-group v-model="wifi.use" @change="changeEvent">
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="dataList" label-width="150px" @submit.native.prevent>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.lineType')+'：'" :required="true">
        <el-row>
          <el-col :span="4">
            <el-select
              size="small"
              v-model="wifi.wifiConnType"
              style="width: 205px"
              :disabled="wifi.use==0"
              @change="changeSelect"
            >
              <el-option
                v-for="item in wifiConnType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.WiFiName')+'：'"
        :required="true"
        v-if="wifi.wifiConnType == 0"
      >
        <el-row>
          <el-col :span="12">
            <el-input
              maxlength=30
              size="small"
              style="width: 205px"
              :disabled="wifi.use==0"
              v-model.trim="wifi.ssidStr"
              @input="changeText"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.MACPosition')+'：'"
        :required="true"
        v-if="wifi.wifiConnType == 1"
      >
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              style="width: 205px"
              :disabled="wifi.use==0"
              v-model="wifi.macStr"
              @input="changeText"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextMac')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.HideNetwork')+'：'">
        <el-checkbox
          :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.NetworkText')"
          v-model="wifi.hiddenNetwork"
          :disabled="wifi.use==0"
          true-label="true"
          false-label="false"
          @change="changeCheck"
        ></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.Automatic')+'：'">
        <el-checkbox
          :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.AutomaticText')"
          :disabled="wifi.use==0"
          v-model="wifi.autoJoin"
          true-label="true"
          false-label="false"
          @change="changeCheck"
        ></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SecurityType')+'：'">
        <el-row>
          <el-col :span="12">
            <el-select
              size="small"
              v-model="wifi.encryptionType"
              style="width: 205px"
              :disabled="wifi.use==0"
              @change="changeSelect"
            >
              <el-option
                v-for="item in encryptionType"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.encryptionType.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SecurityText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.Password')+'：'">
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              style="width: 205px"
              type="password"
              v-model="wifi.password"
              :disabled="wifi.use==0"
              @input="changeText"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.PasswordText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.Agent')+'：'">
        <el-row>
          <el-col :span="12">
            <el-select
              size="small"
              v-model="wifi.proxyType"
              style="width: 205px"
              :disabled="wifi.use==0"
              @change="changeSelect"
            >
              <el-option
                v-for="item in proxyType"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.proxyType.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.proxySpanText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 自动 -->
      <el-form-item
        label="ProxyPACURL："
        :required="true"
        v-if="wifi.proxyType == 'Auto'"
      >
        <el-row>
          <el-col :span="4">
            <el-input
              size="small"
              style="width: 205px"
              :disabled="wifi.use==0"
              v-model="wifi.proxyPacUrl"
              @input="changeText"
            ></el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 手动 -->
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.ServerAddress')+'：'"
        :required="true"
        v-if="wifi.proxyType == 'Manual'"
      >
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              style="width: 205px"
              :disabled="wifi.use==0"
              v-model="wifi.proxyServer"
              @input="changeText"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextAddress')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.Port')+'：'"
        :required="true"
        v-if="wifi.proxyType == 'Manual'"
      >
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              style="width: 205px"
              :disabled="wifi.use==0"
              v-model="wifi.proxyServerPort"
              @input="changeText"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextPort')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.ProxyUsername')+'：'"
        v-if="wifi.proxyType == 'Manual'"
      >
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              style="width: 205px"
              :disabled="wifi.use==0"
              v-model="wifi.proxyUserName"
              @input="changeText"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextName')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.proxyPassword')+'：'"
        v-if="wifi.proxyType == 'Manual'"
      >
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              style="width: 205px"
              :disabled="wifi.use==0"
              v-model="wifi.proxyPassword"
              @input="changeText"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextPassword')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      wifi: {
        use: "0",
        wifiConnType: "0",
        ssidStr: "",
        macStr: "",
        hiddenNetwork: "false",
        autoJoin: "false",
        encryptionType: "None",
        password: "",
        proxyType: "None",
        proxyServer: "",
        proxyServerPort: "",
        proxyUserName: "",
        proxyPassword: "",
        proxyPacUrl: "",
      },
      wifiConnType: [
        {
          value: "0",
          label: "SSID",
        },
        {
          value: "1",
          label: "BSSID",
        },
      ],
      encryptionType: [
        {
          value: "None",
          label: "无",
        },
        {
          value: "WPA2",
          label: "WPA / WPA2",
        },
        {
          value: "WEP",
          label: "WEP",
        },
      ],
      proxyType: [
        {
          value: "None",
          label: "无",
        },
        {
          value: "Manual",
          label: "手动",
        },
        {
          value: "Auto",
          label: "自动",
        },
      ],
    };
  },
  created() {
    if(this.dataList.id) {
     this.wifi = this.dataList.configContent.wifi
     if(this.dataList.configContent.wifi.wifiConnType == undefined) {
       this.$set(this.wifi,"wifiConnType",'0')
     }
     if(this.dataList.configContent.wifi.encryptionType == undefined) {
       this.$set(this.wifi,"encryptionType","None")
     }
     if(this.dataList.configContent.wifi.proxyType == undefined) {
       this.$set(this.wifi,"proxyType","None")
     }
    }
  },
  mounted() {
    
  },
  methods: {
    changeEvent(){
      this.$emit("submitSave",{
        wifi:this.wifi
      })
    },
    changeSelect(){
      this.$emit("submitSave",{
        wifi:this.wifi
      })
    },
    changeText(){
      this.$emit("submitSave",{
        wifi:this.wifi
      })
    },
    changeCheck(){
      this.$emit("submitSave",{
        wifi:this.wifi
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}
</style>
