<template>
  <div>
    <el-row :gutter="4" style="padding: 20px 0 0 40px">
      <el-col :span="24" style="margin: 10px 0 0 0">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
          @submit.native.prevent
        >
          <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.BasicInformation.PolicyName')+'：'" :required="true">
            <el-input
              @input="changeInput"
              v-model.trim="ruleForm.name"
              style="width: 240px;"
              size="small"
              :placeholder="$t('DeviceManage.DevicePolicy.IOSPolicy.BasicInformation.placeholder')"
              maxlength="30"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.BasicInformation.MessagePort')}}</span>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="dialogClose"
      @determine="onchose"
      :titleInfo="titleInfo"
      :width="'240px'"
    /><!-- 提示消息组件 -->
  </div>
</template>

<script>
import pagination from "@/components/pagination/page.vue";
import dialogInfo from "@/components/promptMessage/index";
import Eldialog from "@/components/elDialog";
export default {
  components: {
    Eldialog,
    dialogInfo,
    pagination,
  },
  props: {
    dataList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      condition: "time,geofence",
      times: [
        {
          id: "29",
          name: "322324444",
        },
      ],
      geofences: [
        {
          id: "79",
          name: "test3",
        },
      ],
      devicePolicies: {
        limit: "0",
        bw: "0",
        wifibw: "0",
        networkAccessBW: "0",
        blueToothBW: "0",
        networkAppBW: "0",
        recentsBW: "0",
      },
      radio: "",
      timefenceS: {},
      visibaelFlag: false,
      installFlag: false,
      geograpFlag: false,
      geofences: [],
      times: [],
      condition: [],
      dataForm: [],
      geogrData: [],
      multipleSelection: [],
      queryObj: {
        pageNumber: 1,
        rowNumber: 10,
        sort: "t.lastUpdateTime desc",
        where: {
          search0: "",
        },
      },
      titleInfo: "",
      dictData: [
        {
          name: "0",
          timeOut: "0分钟",
          flag: false,
        },
      ],
      ruleForm: {
        name: "",
        radio: "0",
        valueTime: "min",
        frequency: "1",
      },
      rules: {
        name: [
          { required: true, message: "请输入策略名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "最长输入30个字符，不支持空格及特殊字符",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
            message: "名称不支持特殊字符",
            trigger: "blur",
          },
        ],
      },
      flagStatus:false,
      flagEtatus:false
    };
  },
  created() {
    if(this.dataList.id){
      this.ruleForm.name = this.dataList.name
    }
  },
  methods: {
    changeSelect(){
      this.$emit("submitSave",{
        name:this.ruleForm.name,
        condition:this.condition.join(",")
      })
      console.log(this.condition.join(","))
      if(this.condition.join(",").indexOf("time")!==-1){
        this.flagStatus = true
      }else {
        this.flagStatus = false
      }
      if(this.condition.join(",").indexOf("geofence")!==-1){
        this.flagEtatus = true
      }else{
        this.flagEtatus = false
      }
    },
    changeEvent(val) {
      this.timefenceS = { ...val };
    },
    changeInput() {
      this.$emit("submitSave",{
        name:this.ruleForm.name
      })
    },
    deletes(val) {
      this.dictData.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.dictData.splice(i, 1);
        }
      });
    },
    determine() {},
    deterGeograp() {
      this.times = [];
      this.times.push({
        id: this.timefenceS.id,
        name: this.timefenceS.name,
      });
      this.installFlag = false;
      this.$emit("submitSave",{
        name:this.ruleForm.name,
        condition:this.condition.join(","),
        times:this.times
      })
    },
    deleteGeograp() {
      this.times = [];
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.queryObj.pageNumber = page;
      this.queryObj.rowNumber = limit;
      // 调用查询方法
      this.timefence();
    },
    geograpMine() {
      this.multipleSelection.forEach((item, index) => {
        this.geofences.push({
          id: item.id,
          name: item.name,
        });
      });
      this.$emit("submitSave",{
        name:this.ruleForm.name,
        condition:this.condition.join(","),
        times:this.times,
        geofences:this.geofences
      })
    },
    device(){
      this.$emit("submitSave",{
        name:this.ruleForm.name,
        condition:this.condition.join(","),
        times:this.times,
        geofences:this.geofences,
        devicePolicies:this.devicePolicies
      })
      
    },
    handleClose() {
      this.installFlag = false;
      this.geograpFlag = false;
    },
    dialogClose() {
      this.visibaelFlag = false;
    },
    onchose() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
::v-deep .el-form-item__label {
  color: #807e7e;
}
::v-deep .el-table__empty-block {
  width: 34% !important;
}
::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}
::v-deep .el-checkbox__input.is- + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}
::v-deep .el-radio__input.is- + span.el-radio__label {
  color: #807e7e;
}
</style>