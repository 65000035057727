<template>
  <div>
    <el-row :gutter="4" style="padding: 20px 0 0 40px">
      <el-col :span="24" style="margin: 10px 0 0 0">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" @submit.native.prevent>
          <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.PolicyName')+'：'">
            <el-input @change="changeInput" v-model="ruleForm.name" style="width: 240px;" size="small" :placeholder="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.placeholder')" maxlength="30"></el-input>
            <span style="margin-left: 5px; color: #807e7e" class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.MessagePort')}}</span>
          </el-form-item>
          <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Restrictions')+'：'">
            <el-checkbox-group v-model="condition" style="margin-top:5px;" @change="changeSelect">
              <el-checkbox label="time">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFence')}}</el-checkbox>
              <el-checkbox label="geofence">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFence')}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item v-if="flagStatus" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFence')+'：'" prop="name">
            <div style="display: flex">
              <div style="width: 300px">
                <el-table :data="times" ref="multipleTable" stripe size="mini" tooltip-effect="dark" class="sort_table" :header-cell-style="{ background: '#f7f8fa' }" style="width: 400px">
                  <el-table-column header-align="left" align="left" prop="name" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Name')" width="160" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column header-align="left" align="left" prop="name" :label="$t('public.Controls')" show-overflow-tooltip>
                    <template v-slot="{ row }">
                      <img @click="deleteGeograp(row)" src="@/images/icon_del.png" alt="" style="
                          width: 15px;
                          height: 15px;
                          margin-left: 5px;
                          cursor: pointer;
                        " />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-button style="
                  border-radius: 18px;
                  margin: 0 10px;
                  width: 100px;
                  height: 35px;
                " size="small" type="primary" icon="el-icon-plus" @click="timefence">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFence')}}</el-button>
            </div>
          </el-form-item>
          <el-form-item style="margin: 20px 0" v-if="flagEtatus" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFence')+'：'" prop="name">
            <div style="display: flex">
              <div style="width: 300px; max-height: 218px; overflow: auto">
                <el-table :data="geofences" ref="multipleTable" stripe size="mini" tooltip-effect="dark" class="sort_table" :header-cell-style="{ background: '#f7f8fa' }" style="width: 100%">
                  <el-table-column header-align="left" align="left" prop="name" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Name')" width="160" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column header-align="left" align="left" prop="name" :label="$t('public.Controls')" show-overflow-tooltip>
                    <template v-slot="{ row }">
                      <img @click="deleteOption(row)" src="@/images/icon_del.png" alt="" style="
                          width: 15px;
                          height: 15px;
                          margin-left: 5px;
                          cursor: pointer;
                        " />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-button style="
                  border-radius: 18px;
                  margin: 0 10px;
                  width: 100px;
                  height: 35px;
                " size="small" type="primary" icon="el-icon-plus" @click="geograpClick">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFence')}}</el-button>
            </div>
          </el-form-item>
          <el-form-item v-if="flagEtatus || flagStatus" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Applicables')+'：'" :required="true">
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.limit')" style="margin-top:5px;" v-model="devicePolicies.limit" true-label="1" false-label="0" @change="device"></el-checkbox>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.bw')" style="margin-top:5px;" v-model="devicePolicies.bw" true-label="1" false-label="0" @change="device"></el-checkbox>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.wifibw')" style="margin-top:5px;" v-model="devicePolicies.wifibw" true-label="1" false-label="0" @change="device"></el-checkbox>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.networkAccessBW')" style="margin-top:5px;" v-model="devicePolicies.networkAccessBW" true-label="1" false-label="0" @change="device"></el-checkbox>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.blueToothBW')" style="margin-top:5px;" v-model="devicePolicies.blueToothBW" true-label="1" false-label="0" @change="device"></el-checkbox>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.networkAppBW')" style="margin-top:5px;" v-model="devicePolicies.networkAppBW" true-label="1" false-label="0" @change="device"></el-checkbox>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.recentsBW')" style="margin-top:5px;" v-model="devicePolicies.recentsBW" true-label="1" false-label="0" @change="device"></el-checkbox>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 时间围栏弹框 -->
    <Eldialog v-if="installFlag" @handleClose="handleClose" :title="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.Title')" :dialogVisible="installFlag" :btnTitle="$t('public.Join')" :width="'960px'" :num="'470px'" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="deterGeograp">
      <el-table ref="multipleTable" :data="dataForm" stripe size="mini" tooltip-effect="dark" class="sort_table" :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }" @sort-change="sort" @selection-change="handleSelectionChange" :header-cell-style="{ background: '#f7f8fa' }" style="width: 100%">
        <el-table-column width="50">
          <template v-slot="{ row }">
            <el-radio-group @change="changeEvent(row)" v-model="radio">
              <el-radio :label="row.id">{{ }}</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="name" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Name')" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="groupFullName" :label="$t('public.Section')" width="120" show-overflow-tooltip>
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">
              {{ row.groupFullName.substr(groupFullNameShowByIndex)}}
            </span>

          </template>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="timezone" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.TimeZone')" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="valid" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.TimeFrame')" width="120" show-overflow-tooltip>
          <template v-slot="{ row }">
            <template v-if="row.valid == 1">
              {{ $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.Custom') }}
            </template>
            <template v-if="row.valid == 0">
              {{ $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.PermanentlyValid') }}
            </template>
          </template>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="groupFullName" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.CycleTime')" width="120" show-overflow-tooltip>
          <template v-slot="{ row }">
            <template v-if="row.weekdays.split(',').indexOf('1') != -1">
              {{ $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.weeks.mon') }}
            </template>
            <template v-if="row.weekdays.split(',').indexOf('2') != -1">
              {{ $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.weeks.tue') }}
            </template>
            <template v-if="row.weekdays.split(',').indexOf('3') != -1">
              {{ $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.weeks.wed') }}
            </template>
            <template v-if="row.weekdays.split(',').indexOf('4') != -1">
              {{ $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.weeks.thu') }}
            </template>
            <template v-if="row.weekdays.split(',').indexOf('5') != -1">
              {{ $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.weeks.fri') }}
            </template>
            <template v-if="row.weekdays.split(',').indexOf('6') != -1">
              {{ $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.weeks.sat') }}
            </template>
            <template v-if="row.weekdays.split(',').indexOf('7') != -1">
              {{ $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.weeks.sun') }}
            </template>
          </template>
        </el-table-column>
        <el-table-column header-align="left" align="left" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFenceAlert.RegularTime')" width="120" show-overflow-tooltip>
          <template v-slot="{ row }">
            {{ row.timeStart }}~{{ row.timeEnd }}
          </template>
        </el-table-column>
        <el-table-column prop="lastUpdateTime" :label="$t('public.ModificationTime')" header-align="left" align="left" show-overflow-tooltip>
          <template v-slot="{ row }">
            <template v-if="row.lastUpdateTime">
              {{ row.lastUpdateTime | dateformat }}
            </template>
          </template>
        </el-table-column>
      </el-table>
      <span>{{$t('public.total')}}{{ dataForm.length }}{{$t('public.recordss')}}</span>
      <!-- <pagination
        :page="queryObj.pageNumber"
        :limit="queryObj.rowNumber"
        :total="totalCount"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      /> -->
    </Eldialog>
    <!-- 地理围栏弹框 -->
    <Eldialog v-show="geograpFlag" @handleClose="handleClose" :title="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.Title')" :dialogVisible="geograpFlag" :btnTitle="$t('public.Join')" :width="'800px'" :num="'470px'" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="geograpMine">
      <el-input size="small" v-model="inputText" style="width: 240px" @keyup.enter.native="searcInstall" placeholder="请输入名称"></el-input>
      <el-button style="margin-left:5px;" type="primary" size="small" @click="searcInstall">查询</el-button>
      <el-table ref="multipleTables" :data="geogrData" stripe size="mini" tooltip-effect="dark" class="sort_table" :header-cell-style="{ background: '#f7f8fa' }" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column  align="center" type="selection" width="55" >
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="name" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Name')" width="80" show-overflow-tooltip>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="groupFullName" :label="$t('public.Section')" width="80" show-overflow-tooltip>
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">
              {{ row.groupFullName.substr(groupFullNameShowByIndex)}}
            </span>
          </template>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="longitude" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.Longitude')" width="120" show-overflow-tooltip>
          <template v-slot="{ row }">
              {{row.longitude+','+row.latitude}}
          </template>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="shape" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.Graphical')" width="80" show-overflow-tooltip>
          <template v-slot="{ row }">
            <template v-if="row.shape == 0"> {{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.Circular')}} </template>
            <template v-else> {{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.Rectangle')}} </template>
          </template>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="length" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.Range')" width="80" show-overflow-tooltip>
          <template v-slot="{ row }">
            {{ row.length + $t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.Rice') }}
          </template>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="scope" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.RangeOutside')" width="80" show-overflow-tooltip>
          <template v-slot="{ row }">
            <template v-if="row.scope == 0"> {{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.Internal')}} </template>
            <template v-else> {{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFenceAlert.External')}} </template>
          </template>
        </el-table-column>
        <el-table-column header-align="left" align="left" prop="lastUpdateTime" :label="$t('public.ModificationTime')" show-overflow-tooltip>
        </el-table-column>
      </el-table>

      <span>{{$t('public.total')}}{{ geogrData.length }}{{$t('public.recordss')}}</span>
    </Eldialog>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="dialogClose" @determine="onchose" :titleInfo="titleInfo" :width="'240px'" /><!-- 提示消息组件 -->
  </div>
</template>

<script>
import pagination from '@/components/pagination/page.vue'
import dialogInfo from '@/components/promptMessage/index'
import Eldialog from '@/components/elDialog'
export default {
  components: {
    Eldialog,
    dialogInfo,
    pagination
  },
  props: {
    dataList: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputText:"",
      groupFullNameShowByIndex: '', //用户部门截取的下标
      condition: 'time,geofence',
      times: [
        {
          id: '29',
          name: '322324444'
        }
      ],
      geofences: [
        {
          id: '79',
          name: 'test3'
        }
      ],
      devicePolicies: {
        limit: '0',
        bw: '0',
        wifibw: '0',
        networkAccessBW: '0',
        blueToothBW: '0',
        networkAppBW: '0',
        recentsBW: '0'
      },
      radio: '',
      timefenceS: {},
      visibaelFlag: false,
      installFlag: false,
      geograpFlag: false,
      geofences: [],
      times: [],
      condition: [],
      dataForm: [],
      geogrData: [],
      multipleSelection: [],
      queryObj: {
        pageNumber: 1,
        rowNumber: 10,
        sort: 't.lastUpdateTime desc',
        where: {
          search0: ''
        }
      },
      titleInfo: '',
      dictData: [
        {
          name: '0',
          timeOut: '0分钟',
          flag: false
        }
      ],
      ruleForm: {
        name: '',
        radio: '0',
        valueTime: 'min',
        frequency: '1'
      },
      rules: {
        name: [
          { required: true, message: '请输入策略名称', trigger: 'blur' },
          {
            min: 1,
            max: 30,
            message: '最长输入30个字符，不支持空格及特殊字符',
            trigger: 'blur'
          },
          {
            required: true,
            pattern:
              /[`~!@#$%^&*()\-+=<>?:"{}|,\/;'\\[\]~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im,
            message: '名称不支持特殊字符',
            trigger: 'blur'
          }
        ]
      },
      flagStatus: false,
      flagEtatus: false
    };
  },
  created() {
    if (this.dataList.id) {
      this.condition = this.dataList.configContent.condition.split(',')
      this.changeSelect(this.dataList.configContent.condition.split(','))
      this.ruleForm.name = this.dataList.name
      this.times = this.dataList.configContent.times
      this.geofences = this.dataList.configContent.geofences
      this.geograpList(this.geofences)
      this.timefaceList(this.times)
      if (this.dataList.configContent.devicePolicies !== undefined) {
        this.devicePolicies = this.dataList.configContent.devicePolicies
      }
    }
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },
  methods: {
    searcInstall() {
      this.geograpClick()
    },
    async geograpList(data) {
      let newdata = []
      data.forEach((item) => {
        newdata.push(item.id)
      })
      let params = {
        ids: newdata
      }
      const res = await this.$axios.post(
        '/httpServe/geofence/selectNameList',
        params,
        true
      )
      let dataArray = []
      if(res.data){
        res.data.forEach((item)=>{
          dataArray.push({
            id:item.id,
            name:item.name
          })
        })
        this.geofences = dataArray
        if(res.data == null) {
          this.geofences = []
        }
      }
    },
    async timefaceList(data) {
      let newdata = []
      data.forEach((item) => {
        newdata.push(item.id)
      })
      let params = {
        ids: newdata
      }
      const res = await this.$axios.post(
        '/httpServe/timefence/selectNameList',
        params,
        true
      )
      if(res.data){
        let dataArray = []
        res.data.forEach((item)=>{
          dataArray.push({
            id:item.id,
            name:item.name
          })
        })
        this.times = dataArray
        this.timefenceS.id = this.times[0].id
      }
    },
    changeSelect() {
      console.log(this.condition.join(','))
      if (this.condition.join(',').indexOf('time') !== -1) {
        this.flagStatus = true
      } else {
        this.flagStatus = false
      }
      if (this.condition.join(',').indexOf('geofence') !== -1) {
        this.flagEtatus = true
      } else {
        this.flagEtatus = false
      }
      this.$emit('submitSave', {
        name: this.ruleForm.name,
        condition: this.condition.join(','),
        times: this.times,
        geofences: this.geofences,
        devicePolicies: this.devicePolicies,
        timeFenceIds: this.timefenceS.id
      })
    },
    async geograpClick() {
      this.titleInfo = '选择地理围栏'
      this.geograpFlag = true
      let params = {
        name:this.inputText,
        currentPage: 1,
        pageSize: 99999,
        orderColume: 'lastUpdateTime',
        orderRule: 'DESC'
      }
      const res = await this.$axios.post(
        '/httpServe/geofence/getDataInfo',
        params,
        true
      )
      console.log(res)
      this.geogrData = res.data.content
      this.$nextTick(() => {
        this.geogrData.forEach((item, index) => {
          if(this.geofences.length>0) {
            this.geofences.forEach((i, j) => {
            if (item.id == i.id) {
              this.$refs.multipleTables.toggleRowSelection(item, true)
            }
          })
          }
        })
      })
    },
    async timefence() {
      const res = await this.$axios.post(
        '/httpServe/timefence/getDataInfo',
        {
          currentPage: 1,
          pageSize: 99999,
          orderColume: 'lastUpdateTime',
          orderRule: 'DESC'
        },
        true
      )
      console.log(res)
      this.$nextTick(() => {
        this.dataForm = res.data.content
        this.totalCount = res.data.total
      })
      this.installFlag = true
      if(this.times&&this.times.length == 1) {
        this.radio = this.times[0].id
      }
    },
    changeEvent(val) {
      this.timefenceS = { ...val }
    },
    changeInput() {
      this.$emit('submitSave', {
        name: this.ruleForm.name,
        condition: this.condition.join(','),
        times: this.times,
        geofences: this.geofences,
        devicePolicies: this.devicePolicies,
        timeFenceIds: this.timefenceS.id
      })
    },
    deletes(val) {
      this.dictData.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.dictData.splice(i, 1)
        }
      })
    },
    determine() {},
    deterGeograp() {
      if(this.times){
        this.times = []
      }
      console.log(this.timefenceS)
      if(this.timefenceS&&this.timefenceS.id) {
        // if(!this.times){
          this.times.push({
            id: this.timefenceS.id,
            name: this.timefenceS.name
          })
        // } 
        this.installFlag = false
        this.$emit('submitSave', {
          name: this.ruleForm.name,
          condition: this.condition.join(','),
          times: this.times,
          geofences: this.geofences,
          devicePolicies: this.devicePolicies,
          timeFenceIds: this.timefenceS.id
        })
      }
      this.installFlag = false
    },
    deleteGeograp() {
      this.times = []
      this.timefenceS = {}
      this.radio = ''
      this.$emit('submitSave', {
        name: this.ruleForm.name,
        condition: this.condition.join(','),
        times: this.times,
        geofences: this.geofences,
        devicePolicies: this.devicePolicies,
        timeFenceIds: this.timefenceS?this.timefenceS.id:''
      })
    },
    deleteOption(val) {
      this.geofences.forEach((item, index) => {
        if (val.name == item.name) {
          this.geofences.splice(index, 1)
        }
      })
      this.$emit('submitSave', {
        name: this.ruleForm.name,
        condition: this.condition.join(','),
        times: this.times,
        geofences: this.geofences,
        devicePolicies: this.devicePolicies,
        timeFenceIds: this.timefenceS.id
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.queryObj.pageNumber = page
      this.queryObj.rowNumber = limit
      // 调用查询方法
      this.timefence()
    },
    sort(column) {
      console.log(column.order, column.prop, '----')
      if (column.prop == 'name') {
        if (column.order === 'ascending') {
          this.queryObj.sort = 't.name asc'
        } else if (column.order === 'descending') {
          this.queryObj.sort = 't.name desc'
        } else {
          this.queryObj.sort = 't.name desc'
        }
      } else if (column.prop == 'groupFullName') {
        if (column.order === 'ascending') {
          this.queryObj.sort = 'g.groupFullName asc'
        } else if (column.order === 'descending') {
          this.queryObj.sort = 'g.groupFullName desc'
        } else {
          this.queryObj.sort = 'g.groupFullName desc'
        }
      } else if (column.prop == 'timezone') {
        if (column.order === 'ascending') {
          this.queryObj.sort = 't.timezone asc'
        } else if (column.order === 'descending') {
          this.queryObj.sort = 't.timezone desc'
        } else {
          this.queryObj.sort = 't.timezone desc'
        }
      } else if (column.prop == 'valid') {
        if (column.order === 'ascending') {
          this.queryObj.sort = 't.valid asc'
        } else if (column.order === 'descending') {
          this.queryObj.sort = 't.valid desc'
        } else {
          this.queryObj.sort = 't.valid desc'
        }
      } else if (column.prop == 'lastUpdateTime') {
        if (column.order === 'ascending') {
          this.queryObj.sort = 't.lastUpdateTime asc'
        } else if (column.order === 'descending') {
          this.queryObj.sort = 't.lastUpdateTime desc'
        } else {
          this.queryObj.sort = 't.lastUpdateTime desc'
        }
      }
      console.log(this.queryObj.sort)
      // this.queryObj.orderColume = column.prop;
      this.timefence()
    },
    geograpMine() {
      this.geofences = []
      this.multipleSelection.forEach((item, index) => {
        this.geofences.push({
          id: item.id,
          name: item.name
        })
      })
      var result = []
      var obj = {}
      for (var i = 0; i < this.geofences.length; i++) {
        if (!obj[this.geofences[i].name]) {
          result.push(this.geofences[i])
          obj[this.geofences[i].name] = true
        }
      }
      this.geofences = result
      this.$emit('submitSave', {
        name: this.ruleForm.name,
        condition: this.condition.join(','),
        times: this.times,
        geofences: this.geofences,
        devicePolicies: this.devicePolicies,
        timeFenceIds: this.timefenceS.id
      })
      this.geograpFlag = false
    },
    device() {
      this.$emit('submitSave', {
        name: this.ruleForm.name,
        condition: this.condition.join(','),
        times: this.times,
        geofences: this.geofences,
        devicePolicies: this.devicePolicies,
        timeFenceIds: this.timefenceS.id
      })
    },
    handleClose() {
      this.radio = ""
      this.installFlag = false
      this.geograpFlag = false
    },
    dialogClose() {
      this.visibaelFlag = false
    },
    onchose() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-form-item__label {
  color: #807e7e;
}
::v-deep .el-table__empty-block {
  width: 34% !important;
}
// ::v-deep .el-form-item__content {
//   line-height: 0px;
//   margin-top: 10px;
// }
::v-deep .el-table__empty-block {
  width: 34% !important;
}
::v-deep .el-form-item__content {
  line-height: 0px;
  margin-top: 5px;
}
::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}
::v-deep .el-checkbox__input.is- + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}
::v-deep .el-radio__input.is- + span.el-radio__label {
  color: #807e7e;
}
</style>