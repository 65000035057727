<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.BasicInformation')" name="1">
        <basicInformation :dataList="dictData" @submitSave="basicInfor" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.Limit')" name="2">
        <limit :dataList="dictData" @submitSave="limits" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.password')" name="3">
        <password :dataList="dictData" @submitSave="passwords" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.wifSetting')" name="4">
        <wifSetting :dataList="dictData" @submitSave="wifSettings" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.position')" name="5">
        <position :dataList="dictData" @submitSave="positions" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.ApplyWhite')" name="6">
        <networkAppBW :dataList="dictData" @submitSave="networkAppBWs" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.SingleApplication')" name="7">
        <singleAppMode :dataList="dictData" @submitSave="singleAppModes" />
      </el-tab-pane>
    </el-tabs>
    <span style="margin-left: 10px; font-size: 12px; color: #807e7e">* 代表监督模式生效</span>
    <br />
    <el-button style="width: 100px; margin: 20px 90px" @click="submitSave" type="primary" size="small">保存</el-button>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo"
      :width="'240px'" /><!-- 提示消息组件 -->
  </div>
</template>
<script>
import dialogInfo from "@/components/promptMessage/index";
import basicInformation from "./components/policyIOS/basicInformation.vue";
import limit from "./components/policyIOS/limit.vue";
import password from "./components/policyIOS/password.vue";
import wifSetting from "./components/policyIOS/wifSetting.vue";
import position from "./components/policyIOS/position.vue";
import networkAppBW from "./components/policyIOS/networkAppBW.vue"
import singleAppMode from "./components/policyIOS/singleAppMode.vue"
export default {
  components: {
    dialogInfo,
    basicInformation,
    limit,
    password,
    wifSetting,
    position,
    networkAppBW,
    singleAppMode
  },
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      titleInfo: "",
      visibaelFlag: false,
      activeName: "1",
      dataForm: {
        name: "",
        configType: "1",
        scope: "10",
        configContent: {
          limit: {
            use: "0",
            forceDelayedSoftwareUpdates: "0",
            enforcedSoftwareUpdateDelay: 90,
          },
          password: {
            use: "0",
          },
          wifi: {
            use: "0",
          },
          position: {
            use: "0",
          },
          appWhite: {
            use: "0"
          },
          appOnly: {
            use: "0",
          }
        },
        timeFenceIds: "",
        geoFenceIds: "",
      },
      flagStatus: false,
    };
  },
  created() {
    if (this.dictData.id) {
      this.dataForm.name = this.dictData.name;
      this.dataForm.configContent = this.dictData.configContent;
      console.log(this.dataForm.configContent, "llll")
    }
  },
  mounted() { },
  methods: {
    PromptBounced() {
      this.flagStatus = false;
      if (!this.dataForm.name) {
        this.$message({
          message: this.$t('DeviceManage.DevicePolicy.MessageError1'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "1";
        });
        return false;
      }
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/.test(this.dataForm.name)) {
        this.$message({
          message: this.$t('DeviceManage.DevicePolicy.MessageError2'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "1";
        });
        return false;
      }
      // 功能限制判断
      if (this.dataForm.configContent.limit.use == "1") {
        let limitData = [];
        for (let key in this.dataForm.configContent.limit) {
          if (key !== "use") {
            limitData.push(this.dataForm.configContent.limit[key]);
          }
        }
        if (limitData.join(",").indexOf("1") == -1) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError6'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
        var preg = /^[0-9]+$/;
        if (!preg.exec(this.dataForm.configContent.limit.enforcedSoftwareUpdateDelay) || this.dataForm.configContent.limit.enforcedSoftwareUpdateDelay > 90 || this.dataForm.configContent.limit.enforcedSoftwareUpdateDelay < 1) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError30'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
      }
      // 密码判断
      if (this.dataForm.configContent.password.use == "1") {
        if (!this.dataForm.configContent.password.maxPinAgeinDays) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError31'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "3";
          });
          return false;
        }
        if (
          !/^([1-9]\d*|[0]{1,1})$/gi.test(
            this.dataForm.configContent.password.maxPinAgeinDays
          )
        ) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError32'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "3";
          });
          return false;
        }
        if (!this.dataForm.configContent.password.pinHistory) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError33'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "3";
          });
          return false;
        }
        // console.log(this.dataForm.configContent.password.pinHistory.length)
        if (this.dataForm.configContent.password.pinHistory > 50) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError34'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "3";
          });
          return false;
        }
      }
      // wifi设置
      if (this.dataForm.configContent.wifi.use == "1") {
        if (
          this.dataForm.configContent.wifi.wifiConnType == "0" &&
          !this.dataForm.configContent.wifi.ssidStr
        ) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError11'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "4";
          });
          return false;
        }
        if (this.dataForm.configContent.wifi.wifiConnType == "1") {
          if (!this.dataForm.configContent.wifi.macStr) {
            this.$message({
              message: this.$t('DeviceManage.DevicePolicy.MessageError12'),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "4";
            });
            return false;
          }
          if (
            !/((([a-f0-9]{2}:){5})|(([a-f0-9]{2}-){5}))[a-f0-9]{2}/gi.test(
              this.dataForm.configContent.wifi.macStr
            )
          ) {
            this.$message({
              message: this.$t('DeviceManage.DevicePolicy.MessageError13'),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "4";
            });
            return false;
          }
        }
        if (this.dataForm.configContent.wifi.proxyType == "Manual") {
          if (!this.dataForm.configContent.wifi.proxyServer) {
            this.$message({
              message: this.$t('DeviceManage.DevicePolicy.MessageError35'),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "4";
            });
            return false;
          }
          if (!this.dataForm.configContent.wifi.proxyServerPort) {
            this.$message({
              message: this.$t('DeviceManage.DevicePolicy.MessageError36'),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "4";
            });
            return false;
          }
        }
        if (this.dataForm.configContent.wifi.proxyType == "Auto") {
          if (!this.dataForm.configContent.wifi.proxyPacUrl) {
            this.$message({
              message: this.$t('DeviceManage.DevicePolicy.MessageError37'),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "4";
            });
            return false;
          }
        }
      }
      // 应用白名单
      if (
        this.dataForm.configContent.appWhite.use == "1" &&
        this.dataForm.configContent.appWhite.list.length == 0
      ) {
        this.$message({
          message: this.$t('DeviceManage.DevicePolicy.MessageError18'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "6";
        });
        return false;
      }
      // 单应用模式
      if (
        this.dataForm.configContent.appOnly.use == "1" &&
        this.dataForm.configContent.appOnly.app.length == 0
      ) {
        this.$message({
          message: this.$t('DeviceManage.DevicePolicy.MessageError18'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "7";
        });
        return false;
      }
      // 设备轨迹判断
      if (this.dataForm.configContent.position.use == "1") {
        if (!this.dataForm.configContent.position.frequency) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError14'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "5";
          });
          return false;
        }
        if (
          !/^([1-9]\d*|[0]{1,1})$/gi.test(
            this.dataForm.configContent.position.frequency
          )
        ) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError15'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "5";
          });
          return false;
        }
        if (!this.dataForm.configContent.position.number) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError16'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "5";
          });
          return false;
        }
        if (
          !/^([1-9]\d*|[0]{1,1})$/gi.test(
            this.dataForm.configContent.position.number
          )
        ) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError17'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "5";
          });
          return false;
        }
      }
      this.flagStatus = true;
    },
    handleLoad(data) {
      this.PromptBounced();
    },
    async submitSave() {
      console.log(this.flagStatus);
      this.PromptBounced();
      if (this.flagStatus) {
        if (this.dictData.id) {
          this.dataForm.id = this.dictData.id;
          this.dataForm.action = "edit";
          const res = await this.$axios.post(
            "/httpServe/policy/update",
            this.dataForm,
            true
          );
          if (res.code == 101) {
            this.$message({
              message: this.$t('DeviceManage.DevicePolicy.MessageError29'),
              type: "error",
              offset: 100,
            });
          }
          if (res.code == 200) {
            this.$message({
              message: this.$t('public.SuccessfullySaved'),
              type: "success",
              offset: 100,
            });
            setTimeout(() => {
              this.$emit("goBack");
            }, 2000);
          }
        } else {
          const res = await this.$axios.post(
            "/httpServe/policy/save",
            this.dataForm,
            true
          );
          if (res.code == 101) {
            this.$message({
              message: this.$t('DeviceManage.DevicePolicy.MessageError29'),
              type: "error",
              offset: 100,
            });
          } else {
            this.$message({
              message: this.$t('public.SuccessfullySaved'),
              type: "success",
              offset: 100,
            });
            setTimeout(() => {
              this.$emit("goBack");
            }, 2000);
          }
        }
      }
    },
    basicInfor(val) {
      this.dataForm.name = val.name;
    },
    limits(val) {
      this.dataForm.configContent.limit = val.limit;
    },
    passwords(val) {
      this.dataForm.configContent.password = val.password;
    },
    wifSettings(val) {
      this.dataForm.configContent.wifi = val.wifi;
    },
    positions(val) {
      this.dataForm.configContent.position = val.position;
    },
    networkAppBWs(val) {
      this.dataForm.configContent.appWhite = val.appWhite;
    },
    singleAppModes(val) {
      this.dataForm.configContent.appOnly = val.appOnly;
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    determine() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-form-item__label {
  color: #807e7e;
}

.el-form-item {
  margin-bottom: 0px;
}

.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
  height: 580px;
  //   overflow: auto;
}

::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}

::v-deep .el-tabs__item {
  padding: 0 10px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px !important;
  font-weight: 500;
  color: #999999;
  position: relative;
}

::v-deep .el-tabs__item.is-active {
  color: #000;
}

::v-deep .el-tabs__item:hover {
  color: #000;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
}

::v-deep .el-radio__label {
  font-size: 14px;
}
</style>