<template>
  <div class="password">
    <el-radio-group v-model="appOnly.use" @change="changeEvent">
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="dataList" label-width="150px">
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.SingleApplication.ApplicationList')+'：'" :required="true">
        <div style="display: flex">
          <div style="width: 300px; height: 218px; overflow: auto">
            <el-table
              :data="appOnly.app"
              lazy
              size="mini"
              stripe
              class="tableClass"
              style="width: 300px"
              :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
            >
              <el-table-column width="200" prop="name" :label="$t('public.ApplicationName')">
              </el-table-column>
              <el-table-column
                header-align="left"
                align="left"
                prop="name"
                :label="$t('public.Controls')"
                show-overflow-tooltip
              >
                <template v-slot="{ row }">
                  <img
                    v-if="appOnly.use == 1"
                    @click="deleteGeograp(row)"
                    src="@/images/icon_del.png"
                    alt=""
                    style="
                      width: 15px;
                      height: 15px;
                      margin-left: 5px;
                      cursor: pointer;
                    "
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-button
            v-if="appOnly.use == 1"
            style="
              border-radius: 18px;
              width: 70px;
              height: 30px;
              margin-left: 10px;
            "
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addApply"
            >{{$t('DeviceManage.DevicePolicy.IOSPolicy.SingleApplication.Apply')}}</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <Eldialog
      v-if="geograpFlag"
      @handleClose="handleClose"
      :title="'选择应用'"
      :dialogVisible="geograpFlag"
      :btnTitle="$t('public.Join')"
      :width="'900px'"
      :num="'470px'"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="deterGeograp"
    >
      <el-input
        size="small"
        v-model="inputText"
        style="width: 240px"
        @keyup.enter.native="searcInstall"
        :placeholder="$t('DeviceManage.DevicePolicy.IOSPolicy.SingleApplication.placeholder')"
      ></el-input>
      <el-button
        style="margin-left: 5px"
        type="primary"
        size="small"
        @click="searcInstall"
        >{{$t('public.Inquire')}}</el-button
      >
      <el-table
        ref="multipleTable"
        :data="dataForm"
        stripe
        :row-key="getRowKeys"
        size="mini"
        tooltip-effect="dark"
        class="tableClass"
        :header-cell-style="{ background: '#f7f8fa' }"
        style="width: 100%; margin-top: 10px"
        @current-change="handleSelectionChange"
      >
        <el-table-column header-align="left" align="left" width="55">
          <template slot-scope="scope">
            <el-radio
              class="device_radio"
              :label="scope.row.id"
              v-model="radioId"
              >&nbsp;</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column
          header-align="left"
          align="left"
          prop="appName"
          :label="$t('public.ApplicationName')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          header-align="left"
          align="left"
          prop="packageName"
          :label="$t('public.ApplicationID')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          header-align="left"
          align="left"
          prop="versionName"
          :label="$t('public.Version')"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column header-align="left" align="left" prop="versionName" label="签名证书指纹值" width="120"
                                                        show-overflow-tooltip>
                                                    </el-table-column> -->
        <el-table-column
          header-align="left"
          align="left"
          prop="lastupdatetime"
          :label="$t('public.ModificationTime')"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <pagination
        :page="dataQuery.currentPage"
        :limit="dataQuery.pageSize"
        :total="dataQuery.totle"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetLists"
      />
    </Eldialog>
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog";
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    Eldialog,
    pagination,
  },
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      appOnly: {
        use: "0",
        app: [],
      },
      dataQuery: {
        currentPage: 1,
        pageSize: 10,
        totle: 0,
      },
      bw: {
        use: "0",
        bwType: "0",
        blackWhiteList: [],
      },
      radioId: "",
      inputText: "",
      choosed: "",
      dataForm: [],
      multipleSelection: [],
      geograpFlag: false,
      bwType: [
        {
          value: "1",
          label: "白名单",
        },
      ],
      flagStatus: false,
    };
  },
  created() {
    if (this.dataList.id) {
      this.appOnly = this.dataList.configContent.appOnly;
      if (this.appOnly.use == 1) {
        this.appOnly.app.forEach((item) => {
          this.radioId = item.id;
        });
      }
      if (!this.appOnly.app) {
        this.$set(this.appOnly, "app", []);
      }
    }
  },
  mounted() {},
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    addApply() {
      this.geograpFlag = true;
      this.radioId = ''
      this.dataQuery.currentPage = 1
      this.application()
    },
    async application() {
      if (this.appOnly.app.length > 0 && this.appOnly.use == 1) {
        this.choosed = this.radioId;
      } else {
        this.radioId = "";
      }
      var param = {
        currentPage: this.dataQuery.currentPage,
        pageSize: this.dataQuery.pageSize,
        osTypeId: "10",
        orderColume: "lastupdatetime",
        orderRule: "DESC",
        // appType: this.queryObj.apptype,
        // appStatus: this.queryObj.statu,
        appName: this.inputText,
      };
      const res = await this.$axios.post(
        "/httpServe/entApp/getDataInfo",
        param,
        true
      );
      this.dataQuery.totle = res.data.total;
      this.dataForm = res.data.content;
      this.$nextTick(() => {
        this.dataForm.forEach((item, index) => {
          this.appOnly.app.forEach((i, j) => {
            if (item.id == i.id) {
              // this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      });
    },
    handleParentGetLists(page, limit) {
      this.dataQuery.currentPage = page;
      this.dataQuery.pageSize = limit;
      // 调用查询方法
      this.application();
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
      // console.log(this.multipleSelection, "11111");
    },
    deterGeograp() {
      this.flagStatus = true;
      if (this.radioId == "") {
        this.$message({
          message: "请选择应用",
          type: "error",
          offset: 100,
        });
        return false;
      }
      this.appOnly.app = [];
      // this.multipleSelection.forEach((item, index) => {
      this.appOnly.app.push({
        id: this.multipleSelection.id,
        name: this.multipleSelection.appName,
        identifier: this.multipleSelection.packageName,
      });
      // console.log(this.appOnly.app, "list222");
      // var result = [];
      // var obj = {};
      // for (var i = 0; i < this.appOnly.app.length; i++) {
      //     if (!obj[this.appOnly.app[i].name]) {
      //         result.push(this.appOnly.app[i]);
      //         obj[this.appOnly.app[i].name] = true;
      //     }
      // }
      // this.appOnly.app = result
      this.$emit("submitSave", {
        appOnly: this.appOnly,
      });
      this.geograpFlag = false;
      this.inputText = "";
    },
    handleClose() {
      this.geograpFlag = false;
      this.inputText = "";
      if (this.appOnly.app.length <= 0) {
        this.radioId = "";
      }
      if (this.radioId != this.choosed) {
        this.radioId = this.choosed;
      }
    },
    //查询
    searcInstall() {
      this.dataQuery.currentPage = 1;
      this.application();
    },
    changeEvent() {
      this.$emit("submitSave", {
        appOnly: this.appOnly,
      });
    },
    changeSelect() {
      this.$emit("submitSave", {
        appOnly: this.appOnly,
      });
    },
    deleteGeograp(val) {
      this.appOnly.app.forEach((item, i) => {
        if (item.appName === val.appName) {
          // i 为选中的索引
          this.appOnly.app.splice(i, 1);
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}

.el-radio-group {
  margin-left: 150px;
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
}

::v-deep .el-table__empty-block {
  width: 34% !important;
}

::v-deep .el-form-item__content {
  line-height: 0px;
  margin-top: 5px;
}

::v-deep .device_radio {
  padding-left: 3px;
}
</style>