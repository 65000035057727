<template>
  <div class="password">
    <el-radio-group v-model="recentsBW.use" @change="changeEvent">
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="dataList" label-width="150px">
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.ListType')+'：'">
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              style="width:205px;"
              v-model="recentsBW.type"
              @change="changeSelect"
              :disabled="recentsBW.use == 0"
            >
              <el-option
                v-for="item in type"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.RecentsType.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.RecentsList')+'：'"
        :required="true"
      >
        <el-row>
          <el-col :span="3.5">
            <el-input
              style="margin-top: 5px;width:205px;"
              size="small"
              v-model="recentsBW.recents"
              type="textarea"
              :rows="4"
              @input="changeText"
              :disabled="recentsBW.use == 0"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span style="margin-left:5px;color:#807e7e;">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.macStrTetx')}}</span>
            <br>
            <span style="margin-left:5px;color:#807e7e;">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.macStrText1')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      recentsBW: {
        use: "0",
        type: "0",
        recents: "",
      },
      type: [
        {
          value: "0",
          label: "黑名单",
        },
        {
          value: "1",
          label: "白名单",
        },
      ],
      connType: [
        {
          value: "0",
          label: "SSID",
        },
        {
          value: "1",
          label: "BSSID",
        },
      ],
    };
  },
  created() {
    if(this.dataList.id) {
     this.recentsBW = this.dataList.configContent.recentsBW
     if(this.dataList.configContent.recentsBW.type == undefined){
       this.recentsBW.type = "0"
     }
    }
  },
  mounted() {

  },
  methods: {
    changeEvent(){
      this.$emit("submitSave",{
        recentsBW:this.recentsBW
      })
    },
    changeSelect(){
      this.$emit("submitSave",{
        recentsBW:this.recentsBW
      })
    },
    changeText(){
      this.$emit("submitSave",{
        recentsBW:this.recentsBW
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
</style>
