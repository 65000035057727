<template>
  <div class="password">
    <el-radio-group v-model="position.use" @change="changeEvent">
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="dataList" label-width="150px" @submit.native.prevent>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.Position.Frequency')+'：'" :required="true">
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              @input="changeText"
              style="width:205px;"
              :disabled="position.use == 0"
              oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              v-model="position.frequency"
              :maxlength="4"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.Position.FrequencyText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.Position.Number')+'：'" :required="true">
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              style="width:205px;"
              @input="changeText"
              :disabled="position.use == 0"
              :maxlength="4"
              v-model="position.number"
              oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.Position.NumberText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      position: {
        use: "0",
        frequency: "",
        number: "",
      }
    };
  },
  created() {
    if (this.dataList.id) {
      this.position = this.dataList.configContent.position;
    }
  },
  mounted() {},
  methods: {
    changeEvent() {
      this.$emit("submitSave", {
        position: this.position,
      });
    },
    changeText() {
      this.$emit("submitSave", {
        position: this.position,
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
.amap-wrapper {
width: 300px;
height: 300px;
}
</style>
