<template>
  <div class="password">
    <el-radio-group v-model="networkAccessBW.use" @change="changeEvent">
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="dataList" ref="dataList" label-width="150px">
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.NetworkAccessBW.ListType')+'：'"
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              style="width:205px;"
              v-model="networkAccessBW.type"
              :disabled="networkAccessBW.use==0"
              @change="changeSelect"
            >
              <el-option
                v-for="item in type"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.AndroidPolicy.NetworkAccessBW.NetworkType.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.NetworkAccessBW.WebsiteList')+'：'"
        :required="true"
      >
        <el-row>
          <el-col :span="3.5">
            <el-input
              style="margin-top: 5px;width:205px;"
              size="small"
              :disabled="networkAccessBW.use==0"
              v-model="networkAccessBW.networkStr"
              type="textarea"
              @input="changeText"
              :rows="4"
            ></el-input>
          </el-col>
          <el-col v-if="networkAccessBW.type==0" style="margin-left:;" :span="8">
            <span style="margin-left:5px;color:#807e7e;">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.NetworkAccessBW.networkStrText')}}</span>
            <br>
            <span style="margin-left:5px;color:#807e7e;">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.NetworkAccessBW.networkStrText2')}}</span>
          </el-col>
          <el-col v-else style="margin-left:;" :span="8">
            <span style="margin-left:5px;color:#807e7e;">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.NetworkAccessBW.networkStrText1')}}</span>
            <br>
            <span style="margin-left:5px;color:#807e7e;">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.NetworkAccessBW.networkStrText2')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      networkAccessBW: {
        use: "0",
        type: "0",
        networkStr: "",
      },
      type: [
        {
          value: "0",
          label: "黑名单",
        },
        {
          value: "1",
          label: "白名单",
        },
      ],
      connType: [
        {
          value: "0",
          label: "SSID",
        },
        {
          value: "1",
          label: "BSSID",
        },
      ],
    };
  },
  created() {
    if(this.dataList.id) {
     this.networkAccessBW = this.dataList.configContent.networkAccessBW
     if(!this.dataList.configContent.networkAccessBW.type){
       this.$set(this.networkAccessBW,'type',"0")
     }
    }
  },
  mounted() {

  },
  methods: {
    changeEvent(){
      this.$emit("submitSave",{
        networkAccessBW:this.networkAccessBW
      })
    },
    changeSelect(){
      this.$emit("submitSave",{
        networkAccessBW:this.networkAccessBW
      })
      this.$set(this.networkAccessBW,"networkStr","")
    },
    changeText(){
      this.$emit("submitSave",{
        networkAccessBW:this.networkAccessBW
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
</style>
