<template>
  <div class="password">
    <el-radio-group v-model="smsBW.use" @change="changeEvent">
      <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
      <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
    </el-radio-group>
    <el-form :model="dataList" label-width="150px">
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.ListType') + '：'"
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              style="width: 205px"
              v-model="smsBW.type"
              @change="changeSelect"
              :disabled="smsBW.use == 0"
            >
              <el-option
                v-for="item in type"
                :key="item.value"
                :label="
                  $t(
                    `DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.RecentsType.${item.label}`
                  )
                "
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.RecentsList') + '：'
        "
        :required="true"
      >
        <el-row>
          <el-col :span="3.5">
            <el-input
              style="margin-top: 5px; width: 205px"
              size="small"
              v-model="smsBW.phones"
              type="textarea"
              :rows="4"
              @input="changeText"
              :disabled="smsBW.use == 0"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span style="margin-left: 5px; color: #807e7e">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.macStrTetx')}}</span>
            <br />
            <span style="margin-left: 5px; color: #807e7e">{{
              $t("DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.macStrText1")
            }}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      smsBW: {
        use: "0",
        type: "0",
        phones: "",
      },
      type: [
        {
          value: "0",
          label: "黑名单",
        },
        {
          value: "1",
          label: "白名单",
        },
      ],
    };
  },
  created() {
    if (this.dataList.id) {
      if(this.dataList.configContent.smsBW){
        this.smsBW = this.dataList.configContent.smsBW;
        if (this.dataList.configContent.smsBW.type == undefined) {
          this.smsBW.type = "0";
        }
      }else{
        this.smsBW={
          use: "0",
          type: "0",
          phones: "",
        };
        this.type=[
          {
            value: "0",
            label: "黑名单",
          },
          {
            value: "1",
            label: "白名单",
          }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    changeEvent() {
      this.$emit("submitSave", {
        smsBW: this.smsBW,
      });
    },
    changeSelect() {
      this.$emit("submitSave", {
        smsBW: this.smsBW,
      });
    },
    changeText() {
      this.$emit("submitSave", {
        smsBW: this.smsBW,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
</style>
