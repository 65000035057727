<template>
  <div class="password">
    <el-radio-group v-model="appInstallSourceBW.use" @change="changeEvent">
      <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
      <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
    </el-radio-group>
    <el-form :model="dataList" label-width="150px">
      <el-form-item
        :label="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.appInstallSourceBW.ListType') + '：'
        "
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              style="width: 205px"
              :disabled="appInstallSourceBW.use == 0"
              @change="changeSelect"
              v-model="appInstallSourceBW.bwType"
            >
              <el-option
                v-for="item in bwType"
                :key="item.value"
                :label="
                  $t(
                    `DeviceManage.DevicePolicy.AndroidPolicy.appInstallSourceBW.bwType.${item.label}`
                  )
                "
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.appInstallSourceBW.ApplicationList') +
          '：'
        "
        :required="true"
      >
        <div style="display: flex">
          <div style="width: 500px; height: 218px; overflow: auto">
            <el-table
              :data="appInstallSourceBW.list"
              lazy
              size="mini"
              stripe
              class="sort_table"
              style="width: 500px"
              :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
            >
              <el-table-column
                width="200"
                prop="name"
                :label="$t('public.ApplicationName')"
              >
              </el-table-column>
              <el-table-column
                width="200"
                prop="identifier"
                :label="$t('public.ApplicationID')"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                align="left"
                prop="name"
                :label="$t('public.Controls')"
                show-overflow-tooltip
              >
                <template v-slot="{ row }">
                  <img
                    v-if="appInstallSourceBW.use == 1"
                    @click="deleteGeograp(row)"
                    src="@/images/icon_del.png"
                    alt=""
                    style="width: 15px; height: 15px; margin-left: 5px; cursor: pointer"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-button
            v-if="appInstallSourceBW.use == 1"
            style="border-radius: 18px; width: 70px; height: 30px; margin-left: 10px"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addApply"
            >{{
              $t("DeviceManage.DevicePolicy.AndroidPolicy.appInstallSourceBW.Apply")
            }}</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <Eldialog
      v-if="geograpFlag"
      @handleClose="handleClose"
      :title="'选择应用'"
      :dialogVisible="geograpFlag"
      :btnTitle="$t('public.Join')"
      :width="'900px'"
      :num="'470px'"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="deterGeograp"
    >
      <el-input
        size="small"
        v-model="inputText"
        style="width: 240px"
        @keyup.enter.native="searcInstall"
        :placeholder="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.appInstallSourceBW.placeholder')
        "
      ></el-input>
      <el-button
        style="margin-left: 5px"
        type="primary"
        size="small"
        @click="searcInstall"
        >{{ $t("public.Inquire") }}</el-button
      >
      <el-table
        ref="multipleTable"
        :data="dataForm"
        stripe
        :row-key="getRowKeys"
        size="mini"
        tooltip-effect="dark"
        class="sort_table"
        :header-cell-style="{ background: '#f7f8fa' }"
        style="width: 100%; margin-top: 10px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('public.ApplicationName')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="identifier"
          :label="$t('public.ApplicationID')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="versionName"
          :label="$t('public.Version')"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :label="$t('public.ModificationTime')"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <pagination
        :page="dataQuery.currentPage"
        :limit="dataQuery.pageSize"
        :total="dataQuery.totle"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetLists"
      />
    </Eldialog>
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog";
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    Eldialog,
    pagination,
  },
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      appInstallSourceBW: {
        use: "0",
        bwType: "1",
        list: [],
      },
      dataQuery: {
        currentPage: 1,
        pageSize: 10,
        totle: 0,
      },
      bw: {
        use: "0",
        bwType: "0",
        blackWhiteList: [],
      },
      inputText: "",
      dataForm: [],
      multipleSelection: [],
      geograpFlag: false,
      bwType: [
        {
          value: "1",
          label: "白名单",
        },
      ],
      flagStatus: false,
    };
  },
  created() {
    if (this.dataList.id) {
      if(this.dataList.configContent.appInstallSourceBW){
        this.appInstallSourceBW = this.dataList.configContent.appInstallSourceBW;
        if (this.dataList.configContent.appInstallSourceBW.use == "0") {
          this.appInstallSourceBW.list = [];
        }
        if (this.dataList.configContent.appInstallSourceBW.bwType == undefined) {
          this.appInstallSourceBW.bwType = "1";
        }
      }else{
        this.appInstallsourceBw={
          use:'0',
          bwType:'1',
          list:[],
        };
        this.bw={
          use:'0',
          bwType:'0',
          blackwhiteList:[],
        };
        this.bwType =[
          {
            value:'1',
            label:'白名单'
          }
        ]
    }
      
    }
  },
  mounted() {},
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    handleParentGetLists(page, limit) {
      this.dataQuery.currentPage = page;
      this.dataQuery.pageSize = limit;
      // 调用查询方法
      this.application();
    },
    handleClose() {
      this.inputText = "";
      this.geograpFlag = false;
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = [...val];
    },
    deterGeograp() {
      // if(!this.multipleSelection&&this.multipleSelection.length == 0) {
      //   this.appInstallSourceBW.list = [];
      // }
      this.flagStatus = true;
      this.multipleSelection.forEach((item, index) => {
        this.appInstallSourceBW.list.push({
          id: item.id,
          name: item.name,
          identifier: item.identifier,
        });
      });
      var obj = {}; // 使用一个对象来跟踪已经处理过的name和identifier的组合  
      var result = []; // 存储去重后的结果  
      for (var i = 0; i < this.appInstallSourceBW.list.length; i++) {  
        var item = this.appInstallSourceBW.list[i];  
        var combinedKey = item.name + '-' + item.identifier; // 组合name和identifier作为键
        // 如果name和identifier的组合是唯一的，就加入result  
        if (!obj[combinedKey]) {  
          result.push(item);  
          obj[combinedKey] = true; // 标记这个组合已经处理过  
        }
      }
      // 现在result数组中包含了去重后（基于name和identifier的组合）的对象  
      this.appInstallSourceBW.list = result;
      this.$emit("submitSave", {
        appInstallSourceBW: this.appInstallSourceBW,
      });
      this.$nextTick(() => {
        this.inputText = "";
      });
      this.geograpFlag = false;
    },
    addApply() {
      this.dataQuery.currentPage = 1
      this.geograpFlag = true;
      this.application()
    },
    async application() {
      var param = {
        searchName: this.inputText,
        osType: "20",
        currentPage: this.dataQuery.currentPage,
        pageSize: this.dataQuery.pageSize,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/android/appLib/getDataInfo",
        param,
        true
      );
      this.dataQuery.totle = res.data.total;
      this.dataForm = res.data.content;
      this.$nextTick(() => {
        this.dataForm.forEach((item, index) => {
          if (this.appInstallSourceBW.list) {
            this.appInstallSourceBW.list.forEach((i, j) => {
              console.log(i, "======");
              if (item.id == i.id) {
                // this.$refs.multipleTable.toggleRowSelection(item, true);
              }
            });
          }
        });
      });
    },
    //查询
    searcInstall() {
      this.dataQuery.currentPage = 1;
      this.application();
    },
    changeEvent() {
      this.$emit("submitSave", {
        appInstallSourceBW: this.appInstallSourceBW,
      });
    },
    changeSelect() {
      this.$emit("submitSave", {
        appInstallSourceBW: this.appInstallSourceBW,
      });
    },
    deleteGeograp(val) {
      this.appInstallSourceBW.list.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.appInstallSourceBW.list.splice(i, 1);
        }
      });
      this.multipleSelection = this.appInstallSourceBW.list
      this.$emit("submitSave", {
        appInstallSourceBW: this.appInstallSourceBW,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  padding-top: 20px;
}

.el-radio-group {
  margin-left: 150px;
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
}

::v-deep .el-table__empty-block {
  width: 34% !important;
}

::v-deep .el-form-item__content {
  line-height: 0px;
  margin-top: 5px;
}
</style>
