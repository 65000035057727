<template>
  <div>
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleLoad"
      class="tabs"
    >
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.BasicInformation')"
        name="1"
      >
        <basicInformation :dataList="dictData" @submitSave="basicInfor" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.Limit')" name="2">
        <limit :dataList="dictData" @submitSave="limits" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.password')"
        name="3"
      >
        <password :dataList="dictData" @submitSave="passwords" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.wifSetting')"
        name="4"
      >
        <wifSetting :dataList="dictData" @submitSave="wifSettings" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.position')"
        name="5"
      >
        <position :dataList="dictData" @submitSave="positions" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.bw')" name="6">
        <bw :dataList="dictData" @submitSave="bws" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.wifiBw')"
        name="7"
      >
        <wifiBw :dataList="dictData" @submitSave="wifiBws" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.networkAccessBW')"
        name="8"
      >
        <networkAccessBW :dataList="dictData" @submitSave="networkAccessBWs" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.blueToothBW')"
        name="9"
      >
        <blueToothBW :dataList="dictData" @submitSave="blueToothBWs" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.networkAppBW')"
        name="10"
      >
        <networkAppBW :dataList="dictData" @submitSave="networkAppBWs" />
      </el-tab-pane>
      <!-- v6.3.2 新增 应用安装源白名单 -->
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.appInstallSourceBW')"
        name="11"
      >
        <appInstallSourceBW :dataList="dictData" @submitSave="appInstallBWs" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.recentsBW')"
        name="12"
      >
        <recentsBW :dataList="dictData" @submitSave="recentsBWs" />
      </el-tab-pane>
      <!-- 上海银行2024新增 -->
      <el-tab-pane
        :label="$t('DeviceManage.DevicePolicy.Tabs.smsBW')"
        name="13"
      >
        <smsBW :dataList="dictData" @submitSave="smsBWs" />
      </el-tab-pane>
    </el-tabs>
    <span style="margin-left: 10px; font-size: 12px; color: #807e7e">{{
      $t("DeviceManage.DevicePolicy.SpanText")
    }}</span>
    <br />
    <el-button
      style="width: 100px; margin: 20px 90px"
      @click="submitSave"
      type="primary"
      size="small"
      >{{ $t("public.Save") }}</el-button
    >
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    /><!-- 提示消息组件 -->
  </div>
</template>
<script>
import bw from "./components/policyAndroid/bw.vue"; // 应用黑白名单
import limit from "./components/policyAndroid/limit.vue"; // 功能限制
import dialogInfo from "@/components/promptMessage/index.vue";
import password from "./components/policyAndroid/password.vue";
import position from "./components/policyAndroid/position.vue";
import wifiBw from "./components/policyAndroid/wifiBw.vue";
import wifSetting from "./components/policyAndroid/wifSetting.vue";
import recentsBW from "./components/policyAndroid/recentsBW.vue";
import blueToothBW from "./components/policyAndroid/blueToothBW.vue";
import networkAppBW from "./components/policyAndroid/networkAppBW.vue"; //网络应用白名单
import networkAccessBW from "./components/policyAndroid/networkAccessBW.vue";
import basicInformation from "./components/policyAndroid/basicInformation.vue";
import smsBW from "./components/policyAndroid/smsBW.vue";
import appInstallSourceBW from "./components/policyAndroid/appInstallSourceBW.vue";
export default {
  components: {
    bw,
    limit,
    wifiBw,
    password,
    position,
    recentsBW,
    wifSetting,
    dialogInfo,
    blueToothBW,
    networkAppBW,
    basicInformation,
    networkAccessBW,
    smsBW,
    appInstallSourceBW,
  },
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      titleInfo: "",
      visibaelFlag: false,
      activeName: "1",
      dataForm: {
        name: "",
        configContent: {
          condition: "",
          times: [],
          geofences: [],
          devicePolicies: {
            limit: "0",
            bw: "0",
            wifibw: "0",
            networkAccessBW: "0",
            blueToothBW: "0",
            networkAppBW: "0",
            recentsBW: "0",
            smsBW: "0",
            appInstallSourceBW: "0",
          },
          limit: {
            use: "0",
          },
          password: {
            use: "0",
          },
          wifi: {
            use: "0",
          },
          position: {
            use: "0",
          },
          bw: {
            use: "0",
          },
          url: {
            use: "0",
          },
          wifiBw: {
            use: "0",
          },
          blueToothBW: {
            use: "0",
          },
          networkAccessBW: {
            use: "0",
          },
          networkAppBW: {
            use: "0",
          },
          recentsBW: {
            use: "0",
          },
          smsBW: {
            use: "0",
          },
          appInstallSourceBW: {
            use: "0",
          },
        },
        configType: "1",
        scope: "20",
        timeFenceIds: "",
        geoFenceIds: "",
      },
      flagStatus: false,
    };
  },
  mounted() {
    if (this.dictData.id) {
      this.dataForm.name = this.dictData.name;
      this.dataForm.configContent = this.dictData.configContent;
      this.dataForm.geoFenceIds = this.dictData.geoFenceIds;
      this.dataForm.timeFenceIds = this.dictData.timeFenceIds;
      if (!this.dataForm.geoFenceIds) {
        this.dataForm.configContent.geofences = [];
      }
      if (!this.dataForm.timeFenceIds) {
        this.dataForm.configContent.times = [];
      }
      // if(this.dictData.configContent.geofences == undefined){
      //   this.dataForm.configContent.geofences = []
      // }
      // this.dataForm.configContent.devicePolicies = this.dictData.configContent.devicePolicies
      // this.dataForm.configContent.times = this.dictData.configContent.times
      // this.dataForm.configContent.geofences = this.dictData.configContent.geofences
    }
  },
  methods: {
    PromptBounced() {
      this.flagStatus = false;
      if (!this.dataForm.name) {
        this.$message({
          message: this.$t("DeviceManage.DevicePolicy.MessageError1"),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "1";
        });
        return false;
      }
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/.test(this.dataForm.name)) {
        this.$message({
          message: this.$t("DeviceManage.DevicePolicy.MessageError2"),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "1";
        });
        return false;
      }
      if (this.dataForm.configContent.condition.indexOf("time") !== -1) {
        if (this.dataForm.configContent.times.length == 0) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError3"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "1";
          });
          return false;
        }
      }
      if (this.dataForm.configContent.condition.indexOf("geofence") !== -1) {
        console.log(this.dataForm.configContent.geofences);
        if (this.dataForm.configContent.geofences.length == 0) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError4"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "1";
          });
          return false;
        }
        let deviceData = [];
        for (let key in this.dataForm.configContent.devicePolicies) {
          deviceData.push(this.dataForm.configContent.devicePolicies[key]);
        }
        if (deviceData.join(",").indexOf("1") == -1) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError5"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "1";
          });
          return false;
        }
      }
      if (this.dataForm.configContent.condition.indexOf("time") !== -1) {
        let deviceData = [];
        for (let key in this.dataForm.configContent.devicePolicies) {
          deviceData.push(this.dataForm.configContent.devicePolicies[key]);
        }
        if (deviceData.join(",").indexOf("1") == -1) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError5"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "1";
          });
          return false;
        }
      }
      // 功能限制判断
      if (this.dataForm.configContent.limit.use == "1") {
        // 注意：我们单独处理 allowMuteMode，因为它有特殊的 true-label
        const specialCheckbox = "allowMuteMode";
        const checkboxProperties = [
          // ... 其他复选框属性
          "allowBluetooth",
          "allowCamera",
          "allowScreenshots",
          "allowRecord",
          "allowSharefile",
          "allowCopyPaste",
          "allowADBPort",
          "allowUSB",
          "allowPreBrowser",
          "allowSDWrite",
          "allowLocation",
          "allowNFC",
          "allowInfraRed",
          "allowAddAccount",
          "allowRestoreSettings",
          "allowDeveloper",
          "allowUpdateDate",
          "allowAirplaneMode",
          "allowDropDownMenu",
          "allowSafeMode",
          "allowTurnOff",
          "allowWifi",
          "allowHotspot",
          "allowWIFIDirect",
          "allowDatalink",
          "allowAPN",
          "allowVPN",
          "allowPhone",
          "allowMakeCall",
          "allowInboundCall",
          "allowNote",
          "allowSendSMS",
          "allowAccSMS",
          "allowSecondSIM",
          "allowWifiSet",
          "allowSysUpdate",
          "allowBackup",
          "allowOtgStorage",
          "allowMtp",
          "allowRotate",
          "allowBluetoothShareNet",
          "allowUsbShareNet",
          "allowPrint",
          "allowChangeUser",
          "allowBackupAndRecovery",
          "allowFingerprintUnlock",
          "allowMMS",
          "allowUninstallApp",
        ];
        // 检查 allowMuteMode 是否为未选中状态（即值为 "1"）
        let allowMuteModeUnchecked =
          this.dataForm.configContent.limit[specialCheckbox] === "1";
        // 检查其他复选框是否都未被选中（即它们的值不是 "1"，因为通常 "1" 是 true-label）
        // let otherCheckboxesUnchecked = checkboxProperties.every(
        //   (prop) => this.dataForm.configContent.limit[prop] !== "1"
        // );
        // 检查除了use之外是否有任何复选框的值大于0
        let otherCheckboxesUnchecked = checkboxProperties.some(key => this.dataForm.configContent.limit[key] > 0 && key !== 'use');
        // 如果 allowMuteMode 和其他复选框都未被选中，则给出提示
        if (allowMuteModeUnchecked && !otherCheckboxesUnchecked) {
          // 这里给出提示，显示一个消息框
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError6"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
        if (
          this.dataForm.configContent.limit.allowUninstallApp == !0 &&
          this.dataForm.configContent.limit.allowUninstallAppList.length == 0
        ) {
          this.$message({
            message: "请添加应用",
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "2";
          });
          return false;
        }
        // 原有方法--因allowMuteMode 未选中状态（即值为 "1"），不适用
        // let limitData = [];
        // for (let key in this.dataForm.configContent.limit) {
        //   if (key !== "use") {
        //     limitData.push(this.dataForm.configContent.limit[key]);
        //   }
        // }
        // console.log(this.dataForm.configContent.limit,limitData,"limitData");
        // if (limitData.join(",").indexOf("1") == -1 && limitData.join(",").indexOf("2") == -1) {
        //     this.$message({
        //       message: this.$t('DeviceManage.DevicePolicy.MessageError6'),
        //       type: "error",
        //       offset: 100,
        //     });
        //     this.$nextTick(() => {
        //     this.activeName = "2";
        //     });
        //     return false;
        //   }
      }
      // 密码设置判断
      if (
        this.dataForm.configContent.password.use == "1" &&
        this.dataForm.configContent.password.passCodeType == "1"
      ) {
        if (!this.dataForm.configContent.password.password) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError7"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "3";
          });
          return false;
        }
        if (
          !/^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/.test(
            this.dataForm.configContent.password.password
          )
        ) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError8"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "3";
          });
          return false;
        }
        if (this.dataForm.configContent.password.password.length !== 4) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError9"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "3";
          });
          return false;
        }
        if (
          !/^([1-9]\d*|[0]{1,1})$/gi.test(
            this.dataForm.configContent.password.password
          )
        ) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError10"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "3";
          });
          return false;
        }
      }
      // wifi设置判断
      if (this.dataForm.configContent.wifi.use == "1") {
        if (
          this.dataForm.configContent.wifi.wifiConnType == "0" &&
          !this.dataForm.configContent.wifi.ssidStr
        ) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError11"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "4";
          });
          return false;
        }
        if (this.dataForm.configContent.wifi.wifiConnType == "1") {
          if (!this.dataForm.configContent.wifi.macStr) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError12"),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "4";
            });
            return false;
          }
          if (
            !/((([a-f0-9]{2}:){5})|(([a-f0-9]{2}-){5}))[a-f0-9]{2}/gi.test(
              this.dataForm.configContent.wifi.macStr
            )
          ) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError13"),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "4";
            });
            return false;
          }
        }
      }
      // 设备轨迹判断
      if (this.dataForm.configContent.position.use == "1") {
        if (!this.dataForm.configContent.position.frequency) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError14"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "5";
          });
          return false;
        }
        if (
          !/^([1-9]\d*|[0]{1,1})$/gi.test(
            this.dataForm.configContent.position.frequency
          )
        ) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError15"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "5";
          });
          return false;
        }
        if (!this.dataForm.configContent.position.number) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError16"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "5";
          });
          return false;
        }
        if (
          !/^([1-9]\d*|[0]{1,1})$/gi.test(
            this.dataForm.configContent.position.number
          )
        ) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError17"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "5";
          });
          return false;
        }
      }
      // 应用黑白名单判断 策略对象
      if (
        this.dataForm.configContent.bw.use == "1" &&
        this.dataForm.configContent.bw.target == ""
      ) {
        this.$message({
          message: this.$t("DeviceManage.DevicePolicy.MessageError38"),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "6";
        });
        return false;
      }
      // 应用黑白名单判断
      if (
        this.dataForm.configContent.bw.use == "1" &&
        this.dataForm.configContent.bw.blackWhiteList
      ) {
        if (this.dataForm.configContent.bw.blackWhiteList.length == 0) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError18"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "6";
          });
          return false;
        }
      }
      // wifi黑白名单判断
      if (this.dataForm.configContent.wifiBw.use == "1") {
        if (
          this.dataForm.configContent.wifiBw.connType == "0" &&
          !this.dataForm.configContent.wifiBw.ssidStr
        ) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError19"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "7";
          });
          return false;
        }
        if (this.dataForm.configContent.wifiBw.connType == "1") {
          if (!this.dataForm.configContent.wifiBw.macStr) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError12"),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "7";
            });
            return false;
          }
          let dataRecents =
            this.dataForm.configContent.wifiBw.macStr.split("\n");
          for (let index = 0; index < dataRecents.length; index++) {
            if (dataRecents[index] == dataRecents[index + 1]) {
              this.$message({
                message: this.$t("DeviceManage.DevicePolicy.MessageError20"),
                type: "error",
                offset: 100,
              });
              this.$nextTick(() => {
                this.activeName = "7";
              });
              return false;
            }
            if (
              !/((([a-f0-9]{2}:){5})|(([a-f0-9]{2}-){5}))[a-f0-9]{2}/gi.test(
                dataRecents[index]
              )
            ) {
              this.$message({
                message: this.$t("DeviceManage.DevicePolicy.MessageError13"),
                type: "error",
                offset: 100,
              });
              this.$nextTick(() => {
                this.activeName = "7";
              });
              return false;
            }
          }
        }
      }
      // 网络访问黑白名单
      if (this.dataForm.configContent.networkAccessBW.use == "1") {
        if (this.dataForm.configContent.networkAccessBW.type == "0") {
          if (!this.dataForm.configContent.networkAccessBW.networkStr) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError21"),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "8";
            });
            return false;
          }
          let dataRecents =
            this.dataForm.configContent.networkAccessBW.networkStr.split("\n");
          for (let index = 0; index < dataRecents.length; index++) {
            if (dataRecents[index] == dataRecents[index + 1]) {
              this.$message({
                message: this.$t("DeviceManage.DevicePolicy.MessageError22"),
                type: "error",
                offset: 100,
              });
              this.$nextTick(() => {
                this.activeName = "8";
              });
              return false;
            }
            if (
              !/^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(
                dataRecents[index]
              )
            ) {
              this.$message({
                message: this.$t("DeviceManage.DevicePolicy.MessageError23"),
                type: "error",
                offset: 100,
              });
              this.$nextTick(() => {
                this.activeName = "8";
              });
              return false;
            }
          }
        }
        if (this.dataForm.configContent.networkAccessBW.type == "1") {
          if (!this.dataForm.configContent.networkAccessBW.networkStr) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError21"),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "8";
            });
            return false;
          }
          let dataRecents =
            this.dataForm.configContent.networkAccessBW.networkStr.split("\n");
          for (let index = 0; index < dataRecents.length; index++) {
            if (
              !/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(dataRecents[index])
            ) {
              this.$message({
                message: this.$t("DeviceManage.DevicePolicy.MessageError24"),
                type: "error",
                offset: 100,
              });
              this.$nextTick(() => {
                this.activeName = "8";
              });
              return false;
            }
          }
        }
      }
      // 蓝牙黑白名单
      if (this.dataForm.configContent.blueToothBW.use == "1") {
        if (!this.dataForm.configContent.blueToothBW.blueToothStr) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError25"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "9";
          });
          return false;
        }
        let dataRecents =
          this.dataForm.configContent.blueToothBW.blueToothStr.split("\n");
        for (let index = 0; index < dataRecents.length; index++) {
          if (dataRecents[index] == dataRecents[index + 1]) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError26"),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "9";
            });
            return false;
          }
          if (
            !/((([a-f0-9]{2}:){5})|(([a-f0-9]{2}-){5}))[a-f0-9]{2}/gi.test(
              dataRecents[index]
            )
          ) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError13"),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "9";
            });
            return false;
          }
        }
      }
      // 网络应用白名单
      if (
        this.dataForm.configContent.networkAppBW.use == "1" &&
        this.dataForm.configContent.networkAppBW.list.length == 0
      ) {
        this.$message({
          message: this.$t("DeviceManage.DevicePolicy.MessageError18"),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "10";
        });
        return false;
      }
      // 应用安装源白名单
      if (this.dataForm.configContent.appInstallSourceBW) {//版本平滑升级需先判断字段appInstallSourceBW是否存在
        if (
          this.dataForm.configContent.appInstallSourceBW.use == "1" &&
          this.dataForm.configContent.appInstallSourceBW.list.length == 0
        ) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError18"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "11";
          });
          return false;
        }
      }

      // 通话黑白名单
      if (this.dataForm.configContent.recentsBW.use == "1") {
        if (!this.dataForm.configContent.recentsBW.recents) {
          this.$message({
            message: this.$t("DeviceManage.DevicePolicy.MessageError27"),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "12";
          });
          return false;
        }
        let dataRecents =
          this.dataForm.configContent.recentsBW.recents.split("\n");
        for (let index = 0; index < dataRecents.length; index++) {
          // 调整之前 if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/gi.test(  dataRecents[index]))
          //标准版V6.3.1调整电话字符限制为：3～12位全字格式，以此实现类似114、10086等短号的设置与管控。
          if (!/^\d{3,12}$/.test(dataRecents[index])) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError28"),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "12";
            });
            return false;
          }
        }
      }
      // 短信黑白名单---
      if (this.dataForm.configContent.smsBW) {//版本平滑升级需先判断字段smsBW是否存在
        if (this.dataForm.configContent.smsBW.use == "1") {
          if (!this.dataForm.configContent.smsBW.phones) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError27"),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "13";
            });
            return false;
          }
          let dataPhones = this.dataForm.configContent.smsBW.phones.split("\n");
          for (let index = 0; index < dataPhones.length; index++) {
            if (!/^\d{3,12}$/.test(dataPhones[index])) {
              this.$message({
                message: this.$t("DeviceManage.DevicePolicy.MessageError28"),
                type: "error",
                offset: 100,
              });
              this.$nextTick(() => {
                this.activeName = "13";
              });
              return false;
            }
          }
        }
      }

      this.flagStatus = true;
    },
    handleLoad(data) {
      this.PromptBounced();
    },
    async submitSave() {
      this.PromptBounced();
      if (this.flagStatus) {
        if (this.dictData.id) {
          this.dataForm.id = this.dictData.id;
          this.dataForm.action = "edit";
          const res = await this.$axios.post(
            "/httpServe/policy/update",
            this.dataForm,
            true
          );
          if (res.code == 101) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError29"),
              type: "error",
              offset: 100,
            });
          }
          if (res.code == 200) {
            this.$message({
              message: this.$t("public.SuccessfullySaved"),
              type: "success",
              offset: 100,
            });
            setTimeout(() => {
              this.$emit("goBack");
            }, 2000);
          }
        } else {
          const res = await this.$axios.post(
            "/httpServe/policy/save",
            this.dataForm,
            true
          );
          console.log(this.dataForm, "this.dataForm");
          if (res.code == 101) {
            this.$message({
              message: this.$t("DeviceManage.DevicePolicy.MessageError29"),
              type: "error",
              offset: 100,
            });
          } else {
            this.$message({
              message: this.$t("public.SuccessfullySaved"),
              type: "success",
              offset: 100,
            });
            setTimeout(() => {
              this.$emit("goBack");
            }, 2000);
          }
        }
      }
    },
    basicInfor(val) {
      console.log(val);
      if (val.geofences && val.geofences.length > 0) {
        let dataArray = [];
        val.geofences.forEach((item) => {
          dataArray.push(item.id);
        });
        this.dataForm.geoFenceIds = dataArray.join(",");
      }
      this.dataForm.name = val.name;
      this.dataForm.configContent.condition = val.condition;
      this.dataForm.configContent.times = val.times;
      this.dataForm.configContent.geofences = val.geofences;
      this.dataForm.configContent.devicePolicies = val.devicePolicies;
      this.dataForm.timeFenceIds = val.timeFenceIds;
      console.log(this.dataForm, "123456798");
    },
    limits(val) {
      this.dataForm.configContent.limit = val.limit;
      console.log(this.dataForm.configContent.limit);
    },
    passwords(val) {
      console.log(val);
      this.dataForm.configContent.password = val.password;
    },
    wifSettings(val) {
      console.log(val);
      this.dataForm.configContent.wifi = val.wifi;
    },
    positions(val) {
      console.log(val);
      this.dataForm.configContent.position = val.position;
    },
    bws(val) {
      console.log(val);
      this.dataForm.configContent.bw = val.bw;
    },
    wifiBws(val) {
      this.dataForm.configContent.wifiBw = val.wifiBw;
    },
    networkAccessBWs(val) {
      console.log(val);
      this.dataForm.configContent.networkAccessBW = val.networkAccessBW;
    },
    blueToothBWs(val) {
      this.dataForm.configContent.blueToothBW = val.blueToothBW;
    },
    networkAppBWs(val) {
      this.dataForm.configContent.networkAppBW = val.networkAppBW;
    },
    appInstallBWs(val) {
      this.dataForm.configContent.appInstallSourceBW = val.appInstallSourceBW;
    },
    recentsBWs(val) {
      this.dataForm.configContent.recentsBW = val.recentsBW;
    },
    smsBWs(val) {
      this.dataForm.configContent.smsBW = val.smsBW;
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    determine() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-radio__label {
  font-size: 14px;
}
.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-form-item__label {
  color: #807e7e;
}
.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
  height: 580px;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}
::v-deep .el-tabs__item {
  padding: 0 10px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px !important;
  font-weight: 500;
  color: #999999;
  position: relative;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
}
::v-deep .el-tabs__item:hover {
  color: #000;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
}
</style>
