<template>
  <div class="password">
    <div style="margin-left: 150px">
      <el-radio-group v-model="bw.use" @change="changeEvent">
        <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
        <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
      </el-radio-group>
    </div>
    <el-form :model="dataList" label-width="150px">
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.ListType') + '：'"
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              style="width: 205px"
              :disabled="bw.use == 0"
              @change="changeSelect"
              v-model="bw.bwType"
            >
              <el-option
                v-for="item in bwType"
                :key="item.value"
                :label="
                  $t(
                    `DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.bwType.${item.label}`
                  )
                "
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- v6.1.1 新增非安全域应用 -->
      <el-form-item
        :label="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.PolicyObject') + '：'
        "
        :required="true"
      >
        <el-row style="line-height: 32px">
          <el-col :span="1.5">
            <el-checkbox
              style="margin-right: 30px"
              @change="Typecheck"
              v-model="checkAll"
              :label="0"
              :disabled="bw.use == 0"
              >{{
                $t("DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.AllApply")
              }}</el-checkbox
            >
          </el-col>
          <el-col :span="6">
            <el-checkbox-group
              @change="changeRadio"
              v-model="targetArr"
              :disabled="bw.use == 0"
            >
              <el-checkbox v-model="targetArr" v-if="bw.bwType == 0" :label="2">{{
                $t(
                  "DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.NoReinforcedApply"
                )
              }}</el-checkbox>
              <el-checkbox v-model="targetArr" v-else :label="1">{{
                $t("DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.ReinforcedApply")
              }}</el-checkbox>
              <el-checkbox v-model="targetArr" v-if="bw.bwType == 0" :label="20"
                >非安全域应用</el-checkbox
              >
              <el-checkbox v-model="targetArr" v-else :label="10">安全域应用</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.ApplicationList') + '：'
        "
        :required="true"
      >
        <div style="display: flex">
          <div style="width: 500px; height: 218px; overflow: auto">
            <el-table
              :data="bw.blackWhiteList"
              lazy
              size="mini"
              stripe
              class="sort_table"
              style="width: 500px"
              :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
            >
              <el-table-column
                width="200"
                prop="name"
                :label="$t('public.ApplicationName')"
              >
              </el-table-column>
              <el-table-column
                width="200"
                prop="identifier"
                :label="$t('public.ApplicationID')"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                align="left"
                prop="name"
                :label="$t('public.Controls')"
                show-overflow-tooltip
              >
                <template v-slot="{ row }">
                  <img
                    v-if="bw.use == 1"
                    @click="deleteGeograp(row)"
                    src="@/images/icon_del.png"
                    alt=""
                    style="width: 15px; height: 15px; margin-left: 5px; cursor: pointer"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-button
            v-if="bw.use == 1"
            style="border-radius: 18px; width: 70px; height: 30px; margin-left: 10px"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addApply"
            >{{
              $t("DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.Apply")
            }}</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <Eldialog
      v-if="geograpFlag"
      @handleClose="handleClose"
      :title="$t('DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.SelectApply')"
      :dialogVisible="geograpFlag"
      :btnTitle="$t('public.Join')"
      :width="'900px'"
      :num="'470px'"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="deterGeograp"
    >
      <el-input
        size="small"
        v-model="inputText"
        style="width: 240px"
        @keyup.enter.native="searcInstall"
        :placeholder="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.placeholder')
        "
      ></el-input>
      <el-button
        style="margin-left: 5px"
        type="primary"
        size="small"
        @click="searcInstall"
        >{{ $t("public.Inquire") }}</el-button
      >
      <el-table
        ref="multipleTable"
        :data="dataForm"
        stripe
        size="mini"
        :row-key="getRowKeys"
        tooltip-effect="dark"
        class="sort_table"
        :header-cell-style="{ background: '#f7f8fa' }"
        style="width: 100%; margin-top: 10px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('public.ApplicationName')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="identifier"
          :label="$t('public.ApplicationID')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="versionName"
          :label="$t('public.Version')"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :label="$t('public.ModificationTime')"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <pagination
        :page="dataQuery.currentPage"
        :limit="dataQuery.pageSize"
        :total="dataQuery.totle"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetLists"
      />
    </Eldialog>
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog";
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    Eldialog,
    pagination,
  },
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      targetObj: {},
      checkAll: false,
      targetArr: [],
      bw: {
        use: "0",
        bwType: "0",
        target: [],
        blackWhiteList: [],
      },
      inputText: "",
      dataForm: [],
      multipleSelection: [],
      geograpFlag: false,
      dataQuery: {
        currentPage: 1,
        pageSize: 10,
        totle: 0,
      },
      bwType: [
        {
          value: "0",
          label: "黑名单",
        },
        {
          value: "1",
          label: "白名单",
        },
      ],
      flagStatus: false,
    };
  },
  created() {
    if (this.dataList.id) {
      this.bw = this.dataList.configContent.bw;
      if (this.bw.target !==undefined) {
        let checkdet = this.bw.target.split(",").map(Number);
        this.targetArr = checkdet;
        if (this.targetArr[0] == 0) {
          this.checkAll = true;
        }
      }
      if (!this.dataList.configContent.bw.target) {
        this.$set(this.bw, "target", []);
      }
      if (this.dataList.configContent.bw.blackWhiteList == undefined) {
        this.bw.blackWhiteList = [];
      }
    }
  },
  mounted() {
    this.show();
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    handleParentGetLists(page, limit) {
      this.dataQuery.currentPage = page;
      this.dataQuery.pageSize = limit;
      // 调用查询方法
      this.application();
    },
    show() {
      if (this.bw.use == 0) {
        this.bw.bwType = "0";
      }
    },
    handleClose() {
      this.geograpFlag = false;
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = [...val];
    },
    deterGeograp() {
      // this.bw.blackWhiteList = [];
      this.flagStatus = true;
      this.multipleSelection.forEach((item, index) => {
        this.bw.blackWhiteList.push({
          id: item.id,
          name: item.name,
          identifier: item.identifier,
        });
      });
      var result = [];
      var obj = {};
      // for (var i = 0; i < this.bw.blackWhiteList.length; i++) {
      //   if (!obj[this.bw.blackWhiteList[i].name] && !obj[this.bw.blackWhiteList[i].identifier]) {
      //     result.push(this.bw.blackWhiteList[i]);
      //     obj[this.bw.blackWhiteList[i].name] = true;
      //     obj[this.bw.blackWhiteList[i].identifier] = true;
      //   }else if (!obj[this.bw.blackWhiteList[i].identifier]) {
      //     result.push(this.bw.blackWhiteList[i]);
      //     obj[this.bw.blackWhiteList[i].identifier] = true;
      //   }else if (!obj[this.bw.blackWhiteList[i].name]) {
      //     result.push(this.bw.blackWhiteList[i]);
      //     obj[this.bw.blackWhiteList[i].name] = true;
      //   }
      // }
      for (var i = 0; i < this.bw.blackWhiteList.length; i++) {  
        var item = this.bw.blackWhiteList[i];  
        var combinedKey = item.name + '-' + item.identifier; // 组合name和identifier作为键
        // 如果name和identifier的组合是唯一的，就加入result  
        if (!obj[combinedKey]) {  
          result.push(item);  
          obj[combinedKey] = true; // 标记这个组合已经处理过  
        }
      }
      this.bw.blackWhiteList = result;
      this.$emit("submitSave", {
        bw: this.bw,
      });
      this.geograpFlag = false;
      this.$nextTick(() => {
        this.inputText = "";
      });
    },
    addApply() {
      this.dataQuery.currentPage = 1
      this.geograpFlag = true;
      this.application()
    },
    async application() {
      this.dataForm = [];
      var param = {
        searchName: this.inputText,
        osType: "20",
        currentPage: this.dataQuery.currentPage,
        pageSize: this.dataQuery.pageSize,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/android/appLib/getDataInfo",
        param,
        true
      );
      console.log(res);
      this.dataQuery.totle = res.data.total;
      this.dataForm = res.data.content;
      this.$nextTick(() => {
        this.dataForm.forEach((item, index) => {
          this.bw.blackWhiteList.forEach((i, j) => {
            if (item.id == i.id) {
              // this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      });
    },
    //查询
    searcInstall() {
      this.dataQuery.currentPage = 1;
      this.application();
    },
    changeEvent() {
      this.$emit("submitSave", {
        bw: this.bw,
      });
    },
    changeSelect() {
      this.checkAll = false;
      this.targetArr = [];
      this.bw.blackWhiteList = [];
      this.$emit("submitSave", {
        bw: this.bw,
      });
    },
    // v6.1.1 多选
    Typecheck(val) {
      if (this.bw.bwType == 0) {
        this.targetArr = val ? [0, 2, 20] : [];
      } else if (this.bw.bwType == 1) {
        this.targetArr = val ? [0, 1, 10] : [];
      }
      var targetStr = JSON.stringify(this.targetArr);
      var targetObj = JSON.parse(targetStr);
      this.bw.target = targetObj.toString();
    },
    changeRadio(val) {
      if (this.checkAll) {
        if (this.targetArr.indexOf(0) === -1) {
          this.targetArr.push("0");
        }
        if (
          val.length !== 20 ||
          val.length !== 10 ||
          val.length == 2 ||
          val.length == 1
        ) {
          this.checkAll = false;
          this.targetArr = [];
        }
      }
      var targetStr = JSON.stringify(this.targetArr);
      var targetObj = JSON.parse(targetStr);
      this.bw.target = targetObj.toString();
      this.$emit("submitSave", {
        bw: this.bw,
      });
    },
    deleteGeograp(val) {
      this.bw.blackWhiteList.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.bw.blackWhiteList.splice(i, 1);
        }
      });
      this.multipleSelection = this.bw.blackWhiteList
      this.$emit("submitSave", {
        bw: this.bw,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  padding-top: 20px;
}
// .el-radio-group {
//   margin-left: 150px;
// }
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
::v-deep .el-table__empty-block {
  width: 34% !important;
}
::v-deep .el-form-item__content {
  line-height: 0px;
  margin-top: 5px;
}
// ::v-deep .el-form-item__content {
//     font-size: 0;
//     display: flex;
//     align-items: flex-end;
// }
</style>
