<template>
  <div class="password">
    <el-radio-group v-model="wifiBw.use" @change="changeEvent">
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="wifiBw" :rules="rules" label-width="150px">
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFibwList.ListType')+'：'">
        <el-row>
          <el-col :span="4">
            <el-select
              size="small"
              style="width:205px;"
              v-model="wifiBw.type"
              @change="changeSelect"
              :disabled="wifiBw.use==0"
            >
              <el-option
                v-for="item in type"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.AndroidPolicy.WiFibwList.WiFibwType.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFibwList.lineType')+'：'">
        <el-row>
          <el-col :span="4">
            <el-select
              size="small"
              style="width:205px"
              v-model="wifiBw.connType"
              @change="changeSelect"
              :disabled="wifiBw.use==0"
            >
              <el-option
                v-for="item in connType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFibwList.WiFiList')+'：'" :required="true">
        <el-row v-if="wifiBw.connType==0">
          <el-col :span="3.5">
            <el-input
              style="margin-top: 5px;width:205px;"
              size="small"
              v-model="wifiBw.ssidStr"
              type="textarea"
              @input="changeText"
              :rows="4"
              :disabled="wifiBw.use==0"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFibwList.WiFiListText')}}</span>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="3.5">
            <el-input
              style="margin-top: 5px;width:205px;"
              size="small"
              v-model="wifiBw.macStr"
              :disabled="wifiBw.use==0"
              type="textarea"
              :rows="4"
              @input="changeText"
            ></el-input>
          </el-col>
          <el-col style="margin-left:5px;color:#807e7e;" :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFibwList.macStrTetx')}}</span>
            <br>
            <span class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFibwList.macStrText1')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      wifiBw: {
        use: "0",
        type: "0",
        connType: "0",
        ssidStr: "",
        macStr: "",
      },
      type: [
        {
          value: "0",
          label: "黑名单",
        },
        {
          value: "1",
          label: "白名单",
        },
      ],
      rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ]
        },
      connType: [
        {
          value: "0",
          label: "SSID",
        },
        {
          value: "1",
          label: "BSSID",
        },
      ],
    };
  },
  created() {
    if(this.dataList.id) {
     this.wifiBw = this.dataList.configContent.wifiBw
     if(this.dataList.configContent.wifiBw.type == undefined){
       this.wifiBw.type= "0"
     }
     if(this.dataList.configContent.wifiBw.connType == undefined){
       this.wifiBw.connType= "0"
     }
    }
  },
  mounted() {
  },
  methods: {
    changeEvent(){
      this.$emit("submitSave",{
        wifiBw:this.wifiBw
      })
    },
    changeSelect(){
      this.$emit("submitSave",{
        wifiBw:this.wifiBw
      })
    },
    changeText(){
      this.$emit("submitSave",{
        wifiBw:this.wifiBw
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
</style>
