<template>
  <div class="password">
    <el-radio-group v-model="wifi.use" @change="changeEvent">
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="wifi" :rules="rules" ref="dictData" label-width="150px" @submit.native.prevent>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.lineType')+'：'">
        <el-row>
          <el-col :span="4">
            <el-select
              size="small"
              v-model="wifi.wifiConnType"
              style="width: 205px"
              :disabled="wifi.use == 0"
              @change="changSelect"
            >
              <el-option
                v-for="item in wifiConnType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :required="true"
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.WiFiName')+'：'"
        v-if="
          wifi.wifiConnType == 0
        "
      >
        <el-row>
          <el-col :span="12">
            <el-input
              maxlength=30
              style="width:205px;"
              size="small"
              @input="changeText"
              :disabled="wifi.use == 0"
              v-model="wifi.ssidStr"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.SpanText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :required="true"
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.MACPosition')+'：'"
        v-if="wifi.wifiConnType == 1"
      >
        <el-row>
          <el-col :span="12">
            <el-input
              size="small"
              style="width:205px;"
              @input="changeText"
              :disabled="wifi.use == 0"
              v-model="wifi.macStr"
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.SpanTextMac')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.HideNetwork')+'：'" >
        <el-checkbox
          :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.NetworkText')"
          v-model="wifi.hiddenNetwork"
          :disabled="wifi.use == 0"
          true-label="1"
          false-label="0"
          @change="changeDuo"
        ></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.Automatic')+'：'" >
        <el-checkbox
          :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.AutomaticText')"
          :disabled="wifi.use == 0"
          v-model="wifi.autoJoin"
          true-label="1"
          false-label="0"
          @change="changeDuo"
        ></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.SecurityType')+'：'" >
        <el-row>
          <el-col :span="12">
            <el-select
              size="small"
              v-model="wifi.encryptionType"
              style="width: 205px"
              :disabled="wifi.use == 0"
              @change="changSelect"
            >
              <el-option
                v-for="item in encryptionType"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.encryptionType.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.SecurityText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.Password')+'：'" >
        <el-row>
          <el-col :span="12">
            <el-input
              style="width:205px;"
              :disabled="wifi.use == 0"
              size="small"
              v-model="wifi.password"
              @input="changeText"
              show-password
            ></el-input>
            <span style="margin-left:5px;color:#807e7e;" class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.PasswordText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      wifi: {
        use: "0",
        wifiConnType: "0",
        ssidStr: "",
        macStr: "",
        hiddenNetwork: "0",
        autoJoin: "0",
        encryptionType: "None",
        password: "",
      },
      wifiConnType: [
        {
          value: "0",
          label: "SSID",
        },
        {
          value: "1",
          label: "BSSID",
        },
      ],
      rules: {
          ssidStr: [
            { required: true, message: '请输入WiFi名称（SSID）', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          macStr:[
            { required: true, message: '请输入MAC地址', trigger: 'blur' },
            {
            required: true,
            pattern: /((([a-f0-9]{2}:){5})|(([a-f0-9]{2}-){5}))[a-f0-9]{2}/gi,
            message: "请填写正确的MAC地址",
            trigger: "blur",
          },
          ]
        },
      encryptionType: [
        {
          value: "None",
          label: '无',
        },
        {
          value: "WPA",
          label: "WPA / WPA2",
        },
        {
          value: "WEP",
          label: "WEP",
        },
      ],
    };
  },
  created() {
    if(this.dataList.id) {
      console.log(this.dataList.configContent.wifi)
      if(this.dataList.configContent.wifi.use == 0) {
        this.wifi = {
          use: "0",
          wifiConnType: "0",
          ssidStr: "",
          macStr: "",
          hiddenNetwork: "0",
          autoJoin: "0",
          encryptionType: "None",
          password: "",
        }
      }else {
        this.wifi = this.dataList.configContent.wifi
      }
     this.$set(this.wifi,"password","")
    }
  },
  mounted() {
    this.show();
  },
  methods: {
    TheCallback() {
      this.$emit("submitSave", {
        wifi:this.wifi,
      });
    },
    changeEvent(){
      this.TheCallback()
    },
    changSelect(){
      this.TheCallback()
    },
    changeText(){
      this.TheCallback()
    },
    changeDuo(){
      this.TheCallback()
    },
    show() {
      if (this.wifi.use == 0) {
        this.wifi.wifiConnType = "0";
        // this.wifi.encryptionType = "无";
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}
</style>
